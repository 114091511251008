.read-later img {
    height: 100px;
    width: 100%;
    object-fit: cover;
}

.account-container {
    background-color: #fff;
    box-shadow: 1px 1px 6px rgba(128, 128, 128, 0.3);
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 40px;
}

.profile_picture {
    width: 85px;
    height: 100px;
    margin: 10px auto;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.profile_picture>img {
    object-fit: cover;
    object-position: top;
}

.profile_picture>.upload_btn {
    position: absolute;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    bottom: -1px;
    right: -1px;
    background-color: #f5c954;
    color: #222;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
}

.profile-form-field label {
    /* min-width: 100px; */
    background-color: #fff;
    transform: translateX(23px) translateY(10px);
    padding: 0 10px;
    font-size: 14px;
}

.profile-form-field input {
    padding: 8px 20px;
    width: 100%;
    border: 1px solid #b7bec7;
    font-size: 16px;
    outline: none;
    border-radius: 7px;
    color: #777;
}

.profile-form-field input:focus {
    border: 1.2px solid #f5c954;
}

.profile-form-field input:focus::placeholder {
    color: #fff;
}

.profile-btn {
    padding: 8px 50px;
    background-color: #122844;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    /* text-transform: capitalize; */
}

.logout-btn {
    background-color: #e1e1e1;
    color: #122844;
    font-weight: 600;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .checkbox-wrapper-13 input[type=checkbox] {
        --active: #13253B;
        --active-inner: #F5C954;
        /* --focus: 2px rgba(39, 94, 254, .3); */
        --border: #BBC1E1;
        --border-hover: #13253B;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    .checkbox-wrapper-13 input[type=checkbox]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }

    .checkbox-wrapper-13 input[type=checkbox]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    .checkbox-wrapper-13 input[type=checkbox]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }

    .checkbox-wrapper-13 input[type=checkbox]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }

    .checkbox-wrapper-13 input[type=checkbox]:disabled+label {
        cursor: not-allowed;
    }

    .checkbox-wrapper-13 input[type=checkbox]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }

    .checkbox-wrapper-13 input[type=checkbox]:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
        width: 21px;
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
        opacity: var(--o, 0);
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
        --o: 1;
    }

    .checkbox-wrapper-13 input[type=checkbox]+label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 4px;
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch) {
        border-radius: 7px;
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }

    .checkbox-wrapper-13 input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }
}

.checkbox-wrapper-13 * {
    box-sizing: inherit;
}

.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
    box-sizing: inherit;
}



.custom-radio-btn {
    /* hiding browser el */
    /* appearance: none; */
    /* Safari support */
    -webkit-appearance: none;
    border: 0.2rem solid #122844;
    padding: 6px;
    background-color: #f3f3f3;
    border-radius: 50%;
    margin: 0;
}

.custom-radio-btn:checked {
    background-color: #f5c954;
    padding: 5.5px;

}