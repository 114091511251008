
.schedule-header h1 {
    font-size: 35px;
    color: #122844;
}

.header-switcher>div {
    padding: 2px 25px;
    border-radius: 4px;
    font-weight: 500;
}

.header-switcher>div.active {
    background-color: #16A9EA;
    color: #fff;
}

.custom-box-shadow {
    box-shadow: 0px 0px 8px #d5d3d3;
}

.schedule-date-box {
    border-radius: 15px;
}

.schedule-time-box {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border: 1px solid #E8E8E8;
    padding: 0 20px 20px;
    border-radius: 6px;
    max-height: 280px;
    overflow: auto;
    scrollbar-width: thin;
}
.schedule-time-box h5{
    padding-top: 15px;
    padding-bottom: 5px;
    background-color: #F8F8F8;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
}
.schedule-time-box .time-box {
    padding: 6px 10px;
    background-color: #F0F0F0;
    border-radius: 10px;
    margin-top: 17px;
    font-weight: 600;
    color: #444444;
    font-size: 15px;
    cursor: pointer;
}
.schedule-time-box .time-box:active{
    transform: scale(0.9);
}
.schedule-time-box .time-box.active-slot{
    background-color: #16A9EA;
    color: #fff;
}
.schedule-time-box .time-box:hover {
    background-color: #16A9EA;
    color: #fff;
}

.schedule-btn {
    padding: 6px 20px;
    font-size: 15.5px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    color: #444444;
}

.blue-button {
    background-color: #16A9EA;
    color: #fff;
}
.schedule-prop-detail{
    border-radius: 10px;
    background-color: #F8F8F8;
}
.schedule-prop-detail img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}
.schedule-prop-detail2 img{
    width: 120px;
    height: 95px;
    border-radius: 10px;
    object-fit: cover;
}
.schedule-prop-detail2 p{
    font-size: 11px !important;
}
.schedule-prop-detail .schedule-btn{
    color: #16A9EA;
}
.schedule-visit-modal .ant-modal-content{
    padding: 0px;
}
.schedule-visit-modal .schedule-prop-detail{
    background-color: transparent;
}
.schedule-visit-modal .schedule-prop-detail img{
    width: 65px;
    height: 65px;
}
.video-option img{
    width: 45px;
}

@media (max-width: 500px) {
    .schedule-time-box .time-box{
        font-size: 12px;
        padding: 6px 8px;
    }
    .schedule-time-box .col-4 > h5{
        font-size: 15px;
    }
}