.blogs {
  padding-top: 5%;
  padding-bottom: 6%;
}

.blogs .section-1 {
  position: relative;
  z-index: -1 !important;
}

.blogs .section-1 .title {
  font-size: 32px;
  margin-bottom: 0px;
}

.blogs .section-1 .title .span1 {
  color: #f1bd34;
}

.blogs .section-1 .title .span2 {
  color: #132845;
  font-weight: 600;
}

.blogs .section-1 hr {
  color: #f5c954;
  height: 3px;
  width: 70px;
  margin: 0px;
  margin-bottom: 10px;
}

.section-2 {
  margin-top: 50px;
}

.news-post {
  display: flex;
  justify-content: space-between;
}

.news-bg-img {
  /* width: 66%; */
  object-fit: cover;
  color: white;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 26px;
}

.trending-news {
  /* width: 31%; */
}

.trending-news .span1 {
  color: #113c64;
  /* color: #113c64; */
  font-size: 22px;
  font-weight: bold;
}

.trending-news hr {
  color: #f1bd34;
  height: 3px;
  width: 95px;
  margin: 0px;
  margin-bottom: 10px;
}

.news-div {
  display: flex;
  flex-direction: column;
}

.news-div .news-div1 {
  display: flex;
  align-items: center;
  margin: 5px 0px;
  cursor: pointer;
}

.news-div .news-div1 img {
  width: 150px;
  object-fit: cover;
  height: 145px;
  border-radius: 10px;
}

.news-div1 .content {
  padding-left: 15px;
  flex: 1;
}

.news-div1 .content span {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  color: rgb(92, 90, 90);
  font-weight: 600;
}

.news-div1 .content .span1 {
  font-size: 16px;
  margin-bottom: 2px;
  color: rgb(92, 90, 90);
}

.news-div1 .content p {
  font-size: 13px;
  margin-bottom: 3px;
  color: rgb(92, 90, 90);
}

.save-text {
  text-align: end;
  /* margin: 30px; */
}

.save-span {
  border: 1px solid #f1bd34;
  border-radius: 50%;
  width: 36px;
  display: grid;
  height: 36px;
  place-items: center;
}

.save-span i {
  color: #f1bd34;
  font-size: 20px;
}

.property {
  position: absolute;
  bottom: 0;
  /* padding: 20px; */
  width: 72%;
}

.property-btn {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #f1bd34;
  color: #132845;
  border: 1px solid transparent;
  font-size: 13px;
  font-weight: 600;
}

.hotprop-content {
  font-size: 22px;
  font-weight: 600;
  margin-top: 15px;
  line-height: 28px;
}

.date {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
}

.date .bar {
  color: #f1bd34;
}

.date i {
  color: #f1bd34;
}

.read1 {
  font-size: 15px;
}

.read-more {
  border: 1px solid #f1bd34;
  padding: 4px 8px;
  border-radius: 50%;
}

.read-more i {
  color: #f1bd34;
  font-size: 18px;
}

.date1 {
  font-size: 11.5px;
  color: rgb(92, 90, 90);
  font-weight: bold;
}

.date1 span {
  color: #f1bd34;
  display: unset !important;
}

.date1 i {
  color: #f1bd34;
  font-size: 16px;
}

.section-1 .span3 {
  font-size: 15.5px;
  width: 50%;
}

.section-3 {
  margin-top: 50px;
  /* width: 66%; */
}

.section-3 .title {
  font-size: 34px;
  color: #132845;
  font-weight: 600;
}

.section-3 .title hr {
  margin: 0px;
  width: 70px;
  height: 3px;
  color: #f2be2c;
}

.news1 {
  width: 66%;
}

.section-3 .content {
  margin-top: 20px;
}

.section-3 .content p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 25px;
}

.bold-text {
  font-weight: bold;
  margin-bottom: 25px;
  color: #113c64;
}

/* BlogCard Css */

.blog-main-heading {
  position: absolute;
  left: 0;
  top: 0;
  width: auto !important;
}

.blog-list {
  /* display: flex; */
  /* justify-content: space-around; */
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
}

.blog-card {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  min-width: 280px;
  /* background-image: url("../../assets/footer/Group\ 48431.png"); */
  min-height: 450px;
  background-size: cover;
  transition: transform 0.2s ease;
  margin: 0 9px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.blog-save {
  text-align: end;
  padding: 20px;
  color: white;
  font-size: 13px;
  padding-right: 16px;
  padding-top: 23px;
  position: relative;
  z-index: 10 !important;
}

.property1 {
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: 100%;
}

.property1-btn {
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #f5c954;
  color: #132845;
  border: 1px solid transparent;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

.hotprop-content1 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 15px;
  color: white;
  line-height: 22px;
  margin-bottom: 5px;
}

.date2 {
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
  font-size: 12px;
}

.date2 .bar {
  color: #f1bd34;
}

.date2 i {
  color: #f1bd34;
  font-size: 15px;
}

.read-more1 {
  border: 1px solid #f1bd34;
  padding: 5px;
  border-radius: 50%;
}

.read-more1 i {
  color: #f1bd34;
  font-size: 18px;
}

.read-btn {
  font-size: 13px;
  color: white;
  margin-left: 6px;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
}

.blog-list .slick-dots {
  position: absolute;
  bottom: 70px;
  text-align: end;
  right: 0px;
  width: auto;
}

.blog-list .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.blog-list .slick-dots li button:before {
  font-size: 10px;
  color: #ccc;
}

.blog-list .slick-dots li.slick-active button:before {
  color: #000;
}

.blog-list .slick-dots .slick-active div {
  background-color: #f1bd34 !important;
  width: 27px !important;
  border-radius: 8px !important;
  margin: 0px !important;
}

.slick-list {
  overflow-y: visible !important;
  overflow-x: clip;
}

.border-left-yellow {
  border-left: 2px solid #f1bd34;
}

/* comments section */
.comments-section {
  background-color: #f5ecd4;
  padding: 25px;
  padding-left: 0px;
  box-shadow: 10px -10px 0px #f1bb3486;
  /* border-radius: 10px; */
}

.comments-section>div {
  max-height: 550px;
  overflow-y: auto !important;
  overflow-x: visible !important;
  scrollbar-width: thin;
  position: relative;
  z-index: 9;
}

.comments-section .paragraph {
  line-height: 22px;
  font-size: 14px !important;
}

.user-details img {
  width: 40px;
  height: 40px;
  border-radius: 500%;
  object-fit: cover;
  object-position: top;
}

.comment-box {
  border-radius: 15px;
}

.comment-box::before {
  content: '';
  background-color: #fff;
  width: 50px;
  height: 40px;
  display: block;
  position: absolute;
  left: -30px;
  top: 20px;
  clip-path: polygon(0 6%, 100% 100%, 100% 0);
  z-index: 10;
}

.comment-field {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  color: #555;
  border: none;
  outline: none;
  border-radius: 15px;
  background-color: #fff;
  height: 140px;
}

.comment-field:focus {
  box-shadow: 0px 0px 10px #0000002c;
}

.comment-btn {
  padding: 10px 30px;
  background-color: #113c64;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 500;
}

.rotate-icon {
  animation: rotate-animation 1s infinite linear;
  display: inline-block;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .section-1 .span3 {
    width: 100% !important;
    margin-bottom: 40px !important;
  }

  .comments-section {
    padding: 15px;
  }

  .comments-section .paragraph {
    line-height: 22px;
    font-size: 14px !important;
  }

  .user-details h5 {
    font-size: 16px;
  }

  .property1 {
    max-width: 600px;
    padding-bottom: 20px;
  }

  .blogs {
    padding: 50px 5px;
  }

  .blog-list {
    margin-top: 35px;
    margin-left: 0px;
    flex-direction: column;
  }

  .blog-card {
    margin: 0 0;
    margin-bottom: 30px;
    min-height: 500px;
  }

  .read-btn {
    margin-bottom: 30px;
  }

  .news-post {
    flex-direction: column;
  }

  .news-bg-img {
    width: 100%;
    height: 450px !important;
  }

  .property {
    width: 100%;
  }

  .hotprop-content {
    font-size: 16px;
  }

  .trending-news {
    width: 100%;
    margin-top: 60px;
  }

  .news-div {
    margin-top: 30px;
  }

  .news-div .news-div1 {
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
  }

  .news-div .news-div1 div {
    width: 100%;
  }

  .news-div .news-div1 img {
    width: 100% !important;
    object-fit: cover;
    height: 200px;
  }

  .news-div1 .content {
    margin-top: 10px;
    padding-left: 0px;
  }

  .section-3 {
    width: 100%;
    margin-top: 40px;
  }

  .hotprop-content1 {
    font-size: 14px;
  }

  .blog-list .slick-dots {
    bottom: -50px !important;
  }

  .blog-list .slick-dots ul {
    padding-left: 0px;
  }

  .blog-list .slick-dots .slick-active div {
    width: 16px !important;
  }

  .blog-list .slick-dots li {
    margin: 0 1px;
  }

  .section-3 .title {
    font-size: 20px;
  }

  .slick-dots {
    position: static;
  }
}

@media (min-width: 1600px) {
  .blog-card {
    min-height: 430px;
  }
}