

/* propuser_locality_banner */
.propuser_locality_banner {
    height: 80vh !important;
    background-size: cover;
    background-position: center;
}

.bd-header-overview2,
.bd-header-overview2 span {
    font-family: "Poppins", sans-serif;
}

.temperature-badge {
    padding: 5px 10px;
    color: white;
    background-color: #2c262676;
    border-radius: 6px;
    display: inline-block;
}

/* tour-card */
.tour-card {
    position: relative;
    padding: 20px;
    border-radius: 16px;
    min-height: 260px;
    overflow: hidden;
}

.tour-card img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    object-fit: cover;
    object-position: 10% 30%;
    transition: all .4s;
}

.tour-card .content {
    position: absolute;
    z-index: 3;
    background-color: #2c262629;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 20px;
    text-shadow: 2px 2px 5px #333;
}

.tour-card:hover img {
    transform: scale(1.15);
}

.tour-card .content h5 {
    font-size: 23px;
}

.card-temperature span {
    display: inline-block;
    padding: 5px 18px 0px;
    border-top: 2px solid white;
    text-shadow: 2px 2px 5px #333;
    font-size: 18px;
}

.card-temperature span:first-child {
    border-right: 2px solid white;
}

/* locality Details page */
.locality-detail-banner {
    min-height: 80vh;
    color: #122844;
}

.locality-detail-banner h4 {
    font-size: 30px;
}

.locality-detail-banner h2 {
    font-size: 45px;
    font-weight: 800;
}

.locality-detail-banner p {
    font-size: 17px;
}

.locality-detail-banner img {
    height: 370px;
    object-fit: cover;
}

.more-locality-card {
    background-color: #fff;
    color: #122844;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px;
    border-radius: 20px;
}

.more-locality-card img {
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
    object-position: 30% 20%;
}

.more-locality-card h4 {
    font-size: 25px;
    font-weight: bold;
}

.more-locality-card p {
    font-size: 14px;
}

.blue-badge-card {
    padding: 1px 10px;
    border-radius: 10px;
    color: #fff;
}

.blue-badge-card p {
    font-size: 14px;
    line-height: 5px;
}

.blue-badge-card span {
    font-size: 10px;
}

.locality-header-content{
    min-width: 500px;
}

@media screen and (max-width: 768px) {
    .locality-header-content{
        min-width: auto;
    }
}

@media screen and (max-width: 600px) {
    .locality-detail-banner {
        min-height: auto;
        padding: 40px 0;
    }

    .locality-detail-banner img {
        height: auto;
    }

    .propuser_locality_banner {
        height: auto !important;
        min-height: 50vh !important;

    }
}