

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.container-box {
    max-width: 1210px;
    margin: auto;
}

.bg-image {
    min-height: 110vh;
    background-size: cover;
    padding: 0px 40px;
}

.form-container {
    padding: 40px 30px;
    /* background-color: #071527ba; */
    background-color: #172c47d3;
    border-radius: 25px;
}

.btn-blue {
    border-radius: 10px;
    background-color: #071527;
    color: #f5c954;
    font-size: 15px;
    border: none;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 17px 30px;
    width: 60%;
    margin: auto;
    display: block;
}

.form-field {
    background-color: #f5c954 !important;
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    color: #fff !important;
    text-shadow: 1px 1px 1px #1111113d;
}
.form-field.bg-white{
    background-color: #fff !important;
    color: #122844 !important;
}
.top-form-section {
    text-align: center;
    font-size: 15px;
}

.top-form-section img {
    height: 60px;
}

.top-form-section h4 {
    color: #f5c954;
    font-weight: 500;
    font-size: 24px;
}

.top-form-section h4 span {
    font-weight: 700;
}

.pass-eye {
    position: absolute;
    right: 15px;
    top: 30%;
    color: #071527;
    font-size: 18px;
    cursor: pointer;
    z-index: 9;
}

.bg-yellow {
    background-color: #f5c954 !important;
}

.bg-dark-blue {
    background-color: #122844 !important;
}

.yellow-box{
    padding: 8px 28px;
    border-radius: 10px;
    background-color: #f5c954;
    display: flex;
    align-items: center;
}

.yellow-box h5{
    font-size: large;
    font-weight: 600;
}

.navbar-nav .nav-item .nav-link {
    font-weight: 400;
}

.paragraph {
    line-height: 26px;
    color: #838383;
    font-size: 15.5px;
}

.paragraph2 {
    color: #444;
}

.text-444{
    color: #444 !important;
}

.yellow-line {
    width: 70px;
    height: 2px;
    border-radius: 5px;
    background-color: #f5c954;
}

.width-85 {
    width: 85%;
    margin: auto;
}

.text-dark-blue {
    color: #122844 !important;
}
.text-dark-blue2{
    color: #3b5999 !important;
}

.color-linkedin {
    color: #0e76a8;
}

.color-twitter {
    color: #1DA1F2;
}

.color-instagram {
    color: #e56969;
}

.color-facebook {
    color: #3b5998;
}

.color-apple {
    color: #fff;
}

.color-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 600px) {
    .top-form-section h4 {
        font-size: 22px;
    }
    .container-box {
        max-width: 90%;
        margin: auto;
    }
}