.news {
    /* padding: 3%; */
    padding-top: 5%;
    padding-bottom: 6%;
}

.news .section-1 {
    text-align: end;
}

.news .section-1 .title {
    font-size: 32px;
    margin-bottom: 0px;
}

.news .section-1 .title .span1 {
    color: #F1BD34;
}

.news .section-1 .title .span2 {
    color: #132845;
    font-weight: bold;
}

.news .section-1 hr {
    color: #F1BD34;
    height: 3px;
    width: 70px;
    margin: 0px;
    margin-bottom: 10px;
    position: relative;
    left: 85.1%;
}

.news .section-2 {
    margin-top: 50px;
}

.news-post {
    display: flex;
    justify-content: space-between;
}

.news-bg-img {
    /* width: 66%; */
    object-fit: cover;
    color: white;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: 680px;
}


.news .trending-news .span1 {
    color: #113c64;
    font-size: 22px;
    font-weight: bold;
}

.news .trending-news hr {
    color: #F1BD34;
    height: 3px;
    width: 95px;
    margin: 0px;
    margin-bottom: 10px;
}

.news .news-div {
    display: flex;
    flex-direction: column;
}

.news .news-div .news-div1 {
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.news .news-div .news-div1 img {
    width: 150px !important;
    object-fit: cover;
    height: 150px;
    border-radius: 10px;
}

.news .news-div1 .content {
    padding-left: 15px;
    flex: 1;
}

.news .news-div1 .content span {
    display: block;
    margin-bottom: 4px;
    font-size: 13.5px;
    color: rgb(92, 90, 90);
    font-weight: bold;
}

.news .news-div1 .content .span1 {
    font-size: 15.5px;
    margin-bottom: 2px;
    color: rgb(92, 90, 90);
}

.news .news-div1 .content p {
    font-size: 13.5px;
    margin-bottom: 3px;
    color: rgb(92, 90, 90);
}

.news .save-span {
    border: 1px solid #F1BD34;
    border-radius: 50%;
}

.news .save-span i {
    color: #F1BD34;
}

.news .property {
    position: absolute;
    bottom: 0;
    /* padding: 20px; */
    width: 72%;
}

.news .property-btn {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #F1BD34;
    color: #132845;
    border: 1px solid transparent;
    font-size: 13px;
    font-weight: 600;
}

.news .hotprop-content {
    font-size: 22px;
    font-weight: 600;
    margin-top: 15px;
    line-height: 28px;
}

.news .date {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
}

.news .date .bar {
    color: #F1BD34;
}

.news .date i {
    color: #F1BD34;
}

.news .read1 {
    font-size: 15px;
}

.news .read-more {
    border: 1px solid #F1BD34;
    padding: 4px 8px;
    border-radius: 50%;
}

.news .read-more i {
    color: #F1BD34;
    font-size: 18px;
}

.news .date1 {
    font-size: 11.5px;
    color: rgb(92, 90, 90);
    font-weight: bold;
}

.news .date1 span {
    color: #F1BD34;
    display: unset !important;
}

.news .date1 i {
    color: #F1BD34;
    font-size: 16px;
}

.news .section-1 .span3 {
    font-size: 15.5px;
    width: 50%;
    margin-left: auto;
    margin-bottom: 80px;
}

.news .section-3 {
    margin-top: 50px;

}

.news .section-3 .title {
    font-size: 35px;
    color: #132845;
    font-weight: bold;
}

.news .section-3 .title hr {
    margin: 0px;
    width: 70px;
    height: 3px;
    color: #f2be2c;
}

.news .news1 {
    width: 66%;
}

.news .section-3 .content {
    margin-top: 20px;
}

.news .section-3 .content p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 25px;
}

.news .bold-text {
    font-weight: bold;
    margin-bottom: 25px;
    color: #113c64;
}

/* newsCard Css */

.news .news-list {
    /* display: flex; */
    /* justify-content: space-around; */
    flex-wrap: wrap;
    margin-top: 4%;

}

.news .news-card {
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    min-width: 280px;
    /* background-image: url("../../assets/footer/Group\ 48431.png"); */
    min-height: 450px;
    background-size: cover;
    transition: transform 0.1s ease;
    margin: 0 9px;
    margin-bottom: 20px;
}

.news .news-save {
    text-align: end;
    padding: 20px;
    color: white;
    font-size: 13px;
    padding-right: 16px;
    padding-top: 23px;
}

.news .property1 {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
}

.news .property1-btn {
    padding: 8px 10px;
    border-radius: 8px;
    background-color: #F5C954;
    color: #132845;
    border: 1px solid transparent;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
}

.news .hotprop-content1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    color: white;
    line-height: 22px;
    margin-bottom: 5px;
}

.news .date2 {
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    font-size: 12px;
}

.news .date2 .bar {
    color: #F1BD34;
}

.news .date2 i {
    color: #F1BD34;
    font-size: 15px;
}

.news .read-more1 {
    border: 1px solid #F1BD34;
    padding: 5px;
    border-radius: 50%;
}

.news .read-more1 i {
    color: #F1BD34;
    font-size: 18px;
}

.news .read-btn {
    font-size: 13px;
    color: white;
    margin-left: 6px;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
}

.news .news-list .slick-dots {
    position: absolute;
    width: auto;
    bottom: 70px;
    text-align: start;
    left: 0px;
}

.news .news-list .slick-dots li {
    display: inline-block;
    margin: 0 4px;
    width: auto;
}

.news .news-list .slick-dots li button:before {
    font-size: 10px;
    color: #ccc;
}

.news .news-list .slick-dots li.slick-active button:before {
    color: #000;
}

.news .news-list .slick-dots .slick-active div {
    background-color: #F1BD34 !important;
    width: 27px !important;
    border-radius: 8px !important;
    margin: 0px !important;
}

.news .news-list .slick-list {
    overflow-y: visible !important;
    overflow-x: clip;
}

.news-filter-select .css-13cymwt-control {
    font-size: 16px !important;

}

.news-filter-select .css-1jqq78o-placeholder {
    padding-bottom: 0px !important;
    color: #122844;
    font-weight: 500;
    font-size: 1.01rem !important;
}

.news-filter-select .css-13cymwt-control {
    padding: 0px 20px !important;
    width: 320px !important;
    height: 40px;
    border-radius: 50px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.news-filter-select .css-t3ipsp-control {
    padding: 0px 20px !important;
    width: 320px !important;
    height: 40px;
    border-radius: 50px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.news-filter-select .css-1dimb5e-singleValue {
    color: #122844;
    font-weight: 500;
    font-size: 1.01rem !important;
}

.news-filter-select select {
    width: 320px !important;
    height: 40px;
}

@media screen and (min-width: 320px) and (max-width: 600px) {

    .news .property1 {
        max-width: 600px;
        padding-bottom: 20px;
    }

    .news {
        padding: 50px 5px;
    }

    .news .section-1 {
        text-align: center;
    }

    .news .section-1 hr {
        left: 19%;
    }

    .news .news-list {
        margin-top: 35px;
        margin-left: 0px;
        flex-direction: column;
    }

    .news .news-card {
        margin: 0 0px;
        margin-bottom: 30px;
        min-height: 400px;
    }

    .news .read-btn {
        margin-bottom: 30px;
    }

    .news .news-post {
        flex-direction: column-reverse;
    }

    .news-bg-img {
        width: 100%;
    }

    .news .news-bg-img {
        width: 100%;
        height: 450px !important;
        margin-top: 50px;
    }

    .news .property {
        width: 100%;
    }

    .news .hotprop-content {
        font-size: 16px;
    }

    .news .trending-news {
        width: 100%;
        margin-top: 60px;
    }

    .news .news-div {
        margin-top: 30px;
    }

    .news .news-div .news-div1 {
        flex-direction: column;
        margin-bottom: 30px;
        width: 100%;
    }

    .news-div .news-div1 div {
        width: 100%;
    }

    .news .news-div .news-div1 img {
        width: 100% !important;
        height: 200px;
    }

    .news .news-div1 .content {
        margin-top: 10px;
        padding-left: 0px;
    }

    .news .section-3 {
        width: 100%;
        margin-top: 40px;
    }

    .news .hotprop-content1 {
        font-size: 14px !important;
        display: block;
        line-break: normal;
    }

    .news .section-3 .title {
        font-size: 20px;
    }

    .news .news-list .slick-dots {
        text-align: center;
        right: 0px;
        bottom: -50px;
        left: 0px;
    }

    .news .news-list .slick-dots ul {
        padding-left: 0px;
    }

    .news .news-list .slick-dots .slick-active div {
        width: 16px !important;
    }

    .news .news-list .slick-dots li {
        margin: 0 2px;
    }

    .news-filter-select .css-13cymwt-control,
    .news-filter-select .css-t3ipsp-control,
    .news-filter-select select {
        width: 100% !important;
    }
}

@media (min-width: 1600px) {
    .news .news-card {
        min-height: 430px;
    }
}