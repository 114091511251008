/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap'); */

.box {
  position: relative;
  bottom: 0px;
  left: 0;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
}

.overlay {
  width: 1000px;
  height: 450px;
  background-color: white;
  z-index: 999;
  position: relative;
  top: 0px;
  margin: auto;
}

.bd-main-section-footer {
  background: url("../../assets/Footer.jpg") no-repeat;
  background-size: 100% 100%;
  height: 660px;
  position: relative;
  z-index: 999;
}
.bd-social-media {
  margin-top: -40px;
  height: 100px;
}

.footer-social-icons{
  display: flex;
  justify-content: center;
}
.footer-social-icons .social-circle{
  background-color: #f2be2c;
  width: 80px;
  height: 80px;
  margin: 0 30px;
  font-size: 36px;
  transform: translateY(-50%);
  color: #122844;
}

.bd-ellipse-icon {
  position: relative;
  top: -60px;
  left: 32px;
}
.bd-youtube-icon {
  position: relative;
  top: -58px;
  left: 23px;
}
.bd-twitter-icon {
  position: relative;
  top: -58px;
  left: 25px;
}
.bd-Instagram-icon {
  position: relative;
  top: -77px;
  left: 25px;
  font-size: 40px;
}
.bd-linkedln-icon {
  position: relative;
  top: -60px;
  left: 28px;
}
.facebook-size {
  width: 18%;
}
.youtube-size {
  width: 40%;
}
.twitter-size {
  width: 35%;
}
.Instagram-size {
  width: 30%;
}
.linkedln-size {
  width: 30%;
}

.responsive-footer {
  display: flex;
  justify-content: center;
}

.ellipse-size {
  width: 85%;
}

.bd-section-all-txt {
  font-size: 13px;
  color: #122844;
}

.bd-basic-sizing {
  padding: 0px 50px 0px;
}

.get-app-img {
  width: 33%;
}
.bd-policy {
  font-size: 14px;
  max-height: 285px;
  overflow: auto;
  scrollbar-width: thin;
  color: #122844;
}
.bd-policy strong {
  /* text-decoration: underline; */
  font-weight: 600;
}
.inform-text {
  font-size: 14px;
}

.bd-section-footer1 {
  width: 20%;
}
.bd-section-footer2 {
  width: 40%;
}

.bd-footer-header {
  font-size: 21px;
  font-weight: 550;
  color: #122844;
}
.bd-text-policy {
  font-size: 16px;
  color: white;
  padding-top: 30px;
  text-align: center;
}
.bd-section-all-txt {
  margin-bottom: 1rem;
}
.bd-section-all-txt a {
  font-size: 13px;
  font-weight: 500;
  color: #122844;
  text-decoration: none;
}
.bd-section-all-txt a:hover {
  color: #122844;
}
/*  */
@media (max-width: 800px) {
  .overlay {
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .responsive-footer {
    display: none;
  }
  .bd-basic-sizing {
    padding: 10px;
  }
  .bd-main-section-footer {
    height: 100%;
  }
  .footer-social-icons .social-circle{
    width: 60px;
    height: 60px;
    margin: 0 20px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1300px) {
  .overlay {
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .footer-overlay{
    width:1000px;
  }
  .responsive-footer {
    margin-left: auto;
  }
}

@media (max-width: 471px) {
  .bd-main-section-footer {
    background-size: cover;
  }
  .bd-section-footer1 {
    width: 50%;
    padding-bottom: 20px;
  }
  .bd-section-footer2 {
    width: 100%;
  }
  .inform-text {
    font-size: 12px;
  }
  .bd-policy {
    font-size: 12px;
  }
  .get-app-img {
    width: 40%;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .bd-text-policy {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer-social-icons .social-circle{
    width: 50px;
    height: 50px;
    margin: 0 8px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 472px) and (max-width: 660px) {
  .bd-section-footer1 {
    width: 33.33%;
  }
  .inform-text {
    font-size: 12px;
  }
  .bd-policy {
    font-size: 12px;
  }
  .get-app-img {
    width: 50%;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .bd-text-policy {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
