.property-view-tab {
    flex: 1;
}

.property-view-tab a {
    padding: 8px 30px;
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
    color: #505050;
    border: 1px solid #F5C954;
    border-radius: 9px;
}

.property-view-tab a:hover {
    background-color: #F5C954;
    color: #fff;
}

.property-view-tab a.active-tab {
    background-color: #F5C954;
    color: #fff;
}

.photo-card {
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f2f2f2;
    position: relative;
    margin-bottom: 40px;
}

.photo-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photo-card>div {
    padding: 15px 20px;
    /* background-color: #0000003c; */
    display: flex;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.photo-card>div>p {
    font-weight: 600;
    font-size: large;
    color: #fff;
    position: relative;
}

.photo-card>div>p::after {
    content: "";
    width: 70px;
    height: 3px;
    border-radius: 5px;
    background-color: #F5C954;
    display: block;
    position: absolute;
    left: 0;
    bottom: -5px;
}

.photo-card video {
    height: 250px;
    width: 100%;
}

.floorplan-card {
    padding: 25px;
    background-color: #e6e7e8;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.floorplan-card img {
    width: 100%;
    height: 170px;
    object-fit: contain;
}

.video-card section {
    position: absolute;
    bottom: 10%;
    left: 20px;
    color: #fff;
}

.propery_show_map .leaflet-popup-tip-container{
    display: none;
}
.propery_show_map .leaflet-popup-content{
    margin: 13px 13px 13px 13px;
}
@media (max-width: 800px) {
    .property-view-tab {
        text-align: left !important;
    }
    .property-view-tab a {
        width: 30%;
        display: inline-block;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .property-view-tab a {
        width: 90%;
    }
}