.bd-banner-rera {
    /* background-image: url("../../assets/rerabanner.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-position: 50% 62%;
    position: relative;
}

.rera-banner-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.bd-rerabanner-section {
    position: relative;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* height: 300px; */
    /* width: 660px; */
    padding: 40px 100px 80px;
    background-color: rgba(0, 0, 0, 0.4);
    /* padding: 210px 0px; */
}

.rera-main-head-banner span {
    color: #f6ca51;
    font-weight: 400;
}

.rera-main-head-banner {
    font-size: 35px;
    color: white;
}

.main-head-bannertext {
    max-width: 580px;
    color: white;
    font-size: 15.5px;
}

.search-div {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.state-p {
    color: #f6ca51;
    font-size: 14px;
    font-weight: 500;
}

.bd-rera-search {
    width: 290px;
    height: 40px !important;
    border-radius: 12px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color:
        #FAF4E4;
}

.bd-rera-search button {
    display: none;
}

.search-button {
    width: 123px;
    height: 40px;
    padding: 10px 20px 10px 20px;
    background-color: #132845;
    border-radius: 12px;
    margin-left: 15px;
    color: #f2be1a;
    cursor: pointer;
}

.search-button p {
    font-size: 14px;
    margin-bottom: 0 !important;
    color: white;
}



.refresh-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #132845;
    color: #f2be1a;
    margin-left: 15px;
    padding: 3px 3px;
    cursor: pointer;
}

.rera-table-section {
    padding-top: 35px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 35px;
}

.rera-table {
    width: 100% !important;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid #132845;
    border-bottom: none;
    overflow: hidden;

}


.rera-table th:not(:last-child),
.rera-table td:not(:last-child) {
    border-right: 1px solid #132845;
}

/* .rera-table-header tr {
    background-color: none!important;
    
    
} */

.rera-table-header {
    background-color: #132845 !important;
}



.rera-table-header th {
    background-color: #132845;
    color: white;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 20px;
    border: none;
}

.rera-table-header th:first-child {
    border-top-left-radius: 12px !important;
}

.rera-table-header th:last-child {
    border-top-right-radius: 10px !important;
}

.rera-table-body tr {
    background-color: white;
}

.rera-table-body td {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    color: #132845;
    border-bottom: 1px solid #132845;
}

.table-button {
    background-color: #f6ca51;
    color: #132845;
    border: none;
    border-radius: 12px;
    size: 14px;
    font-weight: 600;
    padding: 5px 20px;
}

.pagination-text {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}

.rera-slider-div {
    background-color: #FAF4E4;
    text-align: center;
    border-radius: 12px;
}

.rera-slider-div h5 {
    font-size: 16px;
    font-weight: 700;
}

.rera-slider-div p {
    font-size: 12px;
}

@media(max-width:768px) {
    .bd-rerabanner-section {
        width: 100% !important;
        padding: 15px 15px;
    }

    .bd-rerabanner-inner-section {
        width: 100%;

    }

    .rera-main-head-banner {
        font-size: 38px;
    }

    .main-head-bannertext {
        font-size: 12px;
    }

    .rera-banner-overlay {
        height: 65vh;
    }

    .bd-banner-rera {
        min-height: 65vh;
    }

    .state-p {
        margin-left: 0;
    }

    .rera-table-section {
        overflow-x: scroll;
    }

    .search-button {
        margin-left: 0;
    }
}