/* ========================================================================================================================== */
/* new */

.career-text-content h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  color: #132845;
}

.career-text-content p {
  font-weight: 600;
  font-size: 15.5px;
  line-height: 26px;
  color: #132845;
}

.empower_content * {
  color: #fff !important;
}

.yellow-btn {
  /* width: 120px; */
  display: inline-block;
  padding: 11px 15px;
  /* gap: 10px; */
  border-radius: 12px;
  background-color: #f6ca51;
  font-weight: 500;
  color: #132845;
  border: none;
}

.career-counts h2 {
  font-size: 32px;
  margin-bottom: 2px;
}

.career-counts {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 12px 10px;
  /* gap: 16px; */
  border-radius: 12px;
  border: 1px solid #f6ca51;
  background-color: #fffbef;
}

/* value-card */
.value-card {
  width: 100%;
  transition: all 0.3s;
  padding: 20px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #dddddd;
  box-shadow: 0px 6px 2px 0px #0000001a;
  background-color: #ffffff;
  min-height: 330px;
  overflow-y: hidden;
}

.value-card-hover {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.value-card-hover>div {
  position: relative;
  z-index: 10;
}

.value-card-hover>div>p {
  height: 200px;
  overflow: auto;
  scrollbar-width: thin;
}

.value-card-hover .hover_image {
  content: '';
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: auto;
  height: 100%;
  z-index: 5;
  transition: .4s ease-out;
  opacity: 0;
  transform: scale(0);
}

.value-card-hover:hover h5 {
  color: #f5c954 !important;
}

.value-card-hover:hover p {
  color: #fff !important;
}

.value-card-hover:hover .hover_image {
  /* left: 0; */
  opacity: 1;
  transform: scale(1);
}

.value-card:hover {
  background-color: #f5c954;
}

.value-card:hover .yellow-icon {
  background-color: #fff !important;
}

.yellow-icon {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  font-size: 41px;
  transition: all 0.3s;
  display: grid;
  place-items: center;
}

.bg-yellow {
  background-color: #f2be1a;
}

.value-card h5 {
  font-weight: normal;
  line-height: 36px;
  font-size: 24px;
  color: #132845;
  margin-bottom: 10px !important;
}

.value-card p {
  font-size: 15.5px;
  font-weight: 400;
  line-height: 25.89px;
  color: #132845;
}

/* award-card */
.award-card {
  border-radius: 48px;
  min-height: 300px;
  transition: all 0.3s;
}

.award-card:hover {
  background-color: #f5c954;
}

.award-card:hover .yellow-icon {
  background-color: #fff !important;
}

.award-card h5 {
  line-height: 26px;
  font-size: 15.5px;
}

.award-card .yellow-icon {
  height: 140px;
  display: grid;
  place-items: center;
  border-radius: 32px;
}

.bg-light-gray {
  background-color: #f5f5f5;
}

/* life-card */
.life-card {
  padding: 0;
  overflow: hidden;
  min-height: 320px;
}

.life-card:hover {
  background-color: #fff !important;
}

.life-card h5 {
  line-height: 26px;
  font-size: 20px;
}

.life-card h5 * {
  line-height: 26px;
  font-size: 15.5px;
}

.life-card .blue-box {
  min-height: 200px;
  overflow: hidden;
  position: relative;
}

.blue-box .overlay-box {
  position: absolute;
  top: -15%;
  right: -15%;
  width: 38%;
  height: 150%;
  transform: rotate(15deg);
}

.bg-overlay-purple {
  background-color: #3e1f57;
}

.bg-overlay-blue {
  background-color: #364697;
}

.main-life-content {
  width: 90%;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 20px 25px 20px 45px;
}

.life-user {
  width: 100px;
  height: 100px;
  border: 4px solid #f6ca51;
  object-fit: cover;
  object-position: top;
}

.life-icon {
  position: absolute;
  width: 40px;
  top: 18%;
  left: 5%;
}

.trending-card {
  flex-basis: 20%;
}

.trending-card .award-card {
  min-height: 254px !important;
}

.trending-card .yellow-icon {
  width: 88px !important;
  height: 88px;
  margin: auto;
  margin-bottom: 40px;
  border-radius: 24px;
}

select.custom-input-select {
  width: 200px;
  font-size: 14px;
  border: none;
  padding: 11px 20px;
}

select option {
  background-color: #fff;
  color: #132845;
}

/* job */
.job-card {
  background: #faf4e4;
  border: none;
  box-shadow: none;
  width: 90%;
  margin-left: auto;
  overflow: visible;
}

.post-desc-card {
  padding: 20px;
  border-radius: 20px;
  width: 115%;
  transform: translateX(-13%);
}

.post-desc-card .yellow-icon {
  width: 58px !important;
  height: 55px !important;
  font-size: 24px;
}

.job-button {
  /* background-color: #f2be1a; */
  border-radius: 50px;
  border: none;
  color: #132845;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 20px;
}

/* cta-section */
.cta-section {
  padding: 40px 60px;
}

.cta-section:nth-child(1) {
  border-right: 4px solid #f6ca51;
}

.cta-section>div {
  width: 280px;
}

.cta-section h2 {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  color: #132845;
}

.blue-btn {
  padding: 10px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #132845;
  border: none;
}

.career-form-field {
  background-color: #f5f5f5 !important;
  color: #132845 !important;
  padding: 12px 20px;
  text-shadow: none;
  font-weight: 500;
}

select.career-form-field {
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 14px;
  color: #777 !important;
}

input[type="file"].file-yellow-btn::file-selector-button {
  border: none;
  padding: 0.2em 0.9em;
  border-radius: 8px;
  background-color: #f6ca51;
  transition: 1s;
  margin-right: 10px;
  cursor: pointer;
}

input[type="file"].file-yellow-btn::file-selector-button:hover {
  background-color: #d8ad38;
}

/* Design Images users */
.design-circle {
  display: grid;
  place-items: center;
}

.design-circle1 {
  width: 320px;
  height: 320px;
  /* position: absolute; */
  z-index: 6;
  background: linear-gradient(202.47deg, #132845 7.8%, #737373 129.67%);
  bottom: 0%;
  left: 7%;
}

.design-circle2 {
  width: 390px;
  height: 390px;
  /* position: absolute; */
  z-index: 4;
  bottom: -5%;
  left: 0%;
}

.design-circle3 {
  width: 480px;
  height: 480px;
  position: absolute;
  z-index: 2;
  left: -7%;
  bottom: -20%;
}

.blocks-img {
  position: absolute;
  z-index: 1;
  left: 18%;
  bottom: 15%;
}

.blocks-img2 {
  transform: rotate(-180deg);
}

.design-circle2-2 {
  position: absolute;
  z-index: 2;
  bottom: -15%;
  transform: translateX(50%);
}

#Empowerment-Thought {
  position: absolute;
  bottom: 0;
  right: 0;
}

#Empowerment-Thought .design-circle2-2 {
  position: unset !important;
  z-index: unset !important;
  bottom: unset !important;
  transform: unset !important;
}

.design-circle2-2 .design-circle1 {
  background: linear-gradient(168.93deg, #f6ca51 18.77%, #132845 123.23%);
}

.design-image {
  position: relative;
  z-index: 10;
  width: 370px;
}

.value-image {
  width: 525px;
}

.design-btn {
  width: 220px;
  padding: 8px;
  gap: 10px;
  border: 1px 0px 0px 0px;
  position: absolute;
  left: 62%;
  top: 48%;
  z-index: 9;
  background-color: #132845;
  transform: rotate(90deg);
  letter-spacing: 3px;
  text-align: center;
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
}

.design-btn2 {
  background-color: #f6ca51;
  left: 8%;
  top: 45%;
}

/* Job Details */


.post-details p,
.post-details li {
  font-size: 15.5px;
  font-weight: 500;
  line-height: 22px;
  color: #132845;
}

.about-company {
  font-size: 25px;
  margin-top: 15px;
}

.about-company .span1 {
  color: #f6ca51;
}

.about-company .span2 {
  color: #132845;
  font-weight: 700;
}

.flex-div {
  display: flex;
}

.about-div {
  width: 55%;
  margin-right: 30px;
}

.team-div .team-span {
  color: #132845;
  font-size: 20px;
  font-weight: 600;
}

.team-div hr {
  height: 2.5px;
  margin: 5px 0px;
  color: #f2be1a;
  width: 75px;
  margin-top: 0px;
}

.team-div .team-title {
  margin-bottom: 10px;
  margin-top: 15px;
}

.team-div .span2 {
  color: grey;
  font-weight: bold;
  opacity: 0.6;
  font-size: 16px;
}

.key-skills-span {
  background: #f5eedcd6;
  padding: 10px 18px;
  border-radius: 25px;
  margin-right: 10px;
  display: inline flow-root;
  margin-bottom: 20px;
}

.key-skills {
  margin-top: 30px;
}

.key-skills i {
  color: #132845;
}

.apply-now button {
  padding: 10px 40px;
  font-size: 18px;
  color: white;
  background-color: #132845;
  border: none;
}

.apply-now button i {
  color: #f2be1a;
}

.apply-now {
  margin-top: 15px;
}

.carrier-form {
  margin-top: 60px;
  background-color: #80808012;
  padding: 40px;
}

.carrier-form h3 {
  text-align: center;
  font-size: 48px;
}

.carrier-form h3 .span1 {
  color: #f2be1a;
}

.carrier-form h3 .span2 {
  color: #132845;
  font-weight: bold;
}

.carrier-form .span3 {
  text-align: center;
  display: block;
  color: #132845;
  font-size: 18px;
}

.carrier-form .form-div {
  margin-top: 50px;
}

.carrier-form .form-div label {
  font-size: 18px;
  margin-bottom: 10px;
}

.carrier-form .form-div input {
  background-color: white;
  border: none;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
}

.carrier-form .form-div select {
  background-color: white;
  border: none;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  position: relative;
  padding-right: 30px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1.5rem top 50%;
  background-size: 0.65rem auto;
}

/* .carrier-form .form-div select::after{
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) scale(1.5);
    pointer-events: none;
  } */

.submit-btn {
  display: flex;
  justify-content: end;
}

.submit-btn button {
  padding: 12px 80px;
  border: none;
  border-radius: 6px;
  background-color: #f6ca51;
  font-size: 14px;
  font-weight: 500;
}

.skills {
  margin-top: 15px;
}

.skills .skills-span {
  background: beige;
  padding: 6px 18px;
  border-radius: 25px;
  margin-right: 10px;
  display: inline flow-root;
  margin-bottom: 20px;
}

.salary {
  margin-top: 15px;
}

.team-div i {
  color: #f2be1a;
  font-size: 20px;
}

.carrier-text {
  font-size: 15.5px;
}

.carrier-page1 {
  padding: 30px;
}

.about-div p {
  margin: 10px 0px;
}

.about-div li {
  margin: 5px 0px;
}

.ant-modal-wrap.ant-modal-centered {
  z-index: 10000;
}

@media screen and (max-width: 920px) {
  .flex-div {
    flex-direction: column;
  }

  .about-div {
    width: 100%;
  }

  .team-div {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .value-all-box {
    transform: translateX(0%);
  }

  .empower-all-box {
    transform: translateX(0%);
  }

  .career-text-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .career-text-content p {
    font-size: 13px;
    line-height: 26px;
  }

  .job-card {
    width: 100%;
    padding: 20px 10px;
  }

  .post-desc-card {
    transform: translateX(0%);
    width: 100%;
  }

  .job-card h6 {
    font-size: 14px;
  }

  .value-card h5 {
    line-height: 32px;
    font-size: 17px;
  }

  .main-life-content {
    padding: 16px 20px 16px 40px;
  }

  .life-user {
    width: 90px;
    height: 90px;
    border: 4px solid #f6ca51;
  }

  .life-card h5 {
    line-height: 22px;
    font-size: 18px;
  }

  .life-icon {
    position: absolute;
    width: 40px;
    top: 23.5%;
    left: 4%;
  }

  .design-image {
    width: 90%;
  }

  .blocks-img {
    position: absolute;
    z-index: 1;
    left: 10%;
    bottom: 15%;
    width: 80%;
  }

  .design-btn {
    left: 53%;
  }

  .design-btn2 {
    left: -15%;
    top: 30%;
  }

  .cta-section {
    padding: 40px 25px;
  }

  .cta-section h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .cta-section:nth-child(1) {
    border-right: none;
    border-bottom: 2px solid #f6ca51;
  }

  /* Job Details */

  .carrier-page1 {
    padding: 10px !important;
  }

  .about-div {
    width: 100% !important;
  }

  .team-div {
    padding-top: 25px;
  }

  .carrier-form h3 {
    font-size: 2.5rem !important;
    margin-bottom: 10px;
  }

  .carrier-form {
    padding: 30px 20px !important;
  }

  .value-card p {
    font-size: 14px;
  }
}

.joblist-title span {
  color: #132845;
  font-size: 22px;
  font-weight: bold;
}

.posts-btn {
  border: none;
  background-color: #132845;
  color: white;
  padding: 6px 14px;
  font-weight: 600;
  border-radius: 10px;
}

.joblist {
  color: #132845;
}

.applynow {
  border: none;
  padding: 6px 14px;
  font-weight: 600;
  font-size: 14px;
  background-color: #f6ca51;
  border-radius: 10px;
}

.job-btn {
  background-color: #faf4e4;
  border-radius: 50px;
  border: none;
  color: #132845;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 20px;
}

.job-btn1 {
  background-color: #faf4e4;
  border-radius: 50px;
  border: none;
  color: #132845;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 20px;
}

/* vide play-btn */
.play-btn {
  position: absolute;
  width: 80px;
  height: 80px;
  font-size: 50px;
  top: calc(50% - 40px);
  right: calc(50% - 40px);
  border: 0;
  border-radius: 50px;
  background-color: #00000049;
  color: #fff;
  opacity: 0.4;
}

.career-video:hover .play-btn {
  opacity: 1;
}

.career_page .ant-modal-content {
  padding: 0px 0px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-modal-close {
  transform: translateX(20px) translateY(-20px);
  background-color: #d8ad38 !important;
  color: #fff !important;
  border-radius: 50% !important;
}