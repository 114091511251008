@font-face {
  font-family: "Good Times W00 Heavy";
  src: url("../../assets/fonts/good\ times\ rg.otf") format("opentype");
}

.bd-side-bars {
  font-size: 26px !important;
  color: #f2be1a !important;
  padding: 0 !important;
  margin-right: 5px;
}

.mobile_sidebar_box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0000006d;
  top: 0;
  left: 0;
  z-index: 9999;
}

.sidebar {
  width: 74%;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #000 !important;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.5s;
  opacity: 0.9;
  z-index: 100000;
}

.sidebar.active {
  left: 0;
  border: none;
  border-radius: 0;
}

.sd-header {
  padding: 15px;
  text-align: right;
}

.bd-cross-btn {
  font-size: 18px;
  color: #f2be1a;
  background-color: #ffffff11;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-bodys {
  padding: 13px 0;
  border-bottom: 1px solid #f2be1aa3;
  margin: 0px 20px;
}


.sd-bodys a {
  font-size: 16px;
  display: block;
  text-decoration: none;
}

.sidebar .bg-yellow {
  background-color: #282210 !important;
}

.sidebar .bg-yellow a {
  font-size: 14px;
}

.bd-social-footer {
  display: none;
}

.nav-item.bottom-line {
  position: relative;
}

.nav-item.bottom-line .nav-link {
  /* font-family: "Poppins", sans-serif; */
  font-size: 16px;
}

.nav-item .nav-link.active::after {
  content: '';
  background-color: #f2be1a;
  display: block;
  width: 40px;
  height: 3px;
  border-radius: 5px;
  position: absolute;
  bottom: -57%;
  right: calc(50% - 22px);
}

.side-links a.active {
  color: #f5c954;
  font-weight: 600;
  border: none;
}

.makeover-box {
  /* height: 110vh; */
  background-image: url('../../assets/MakeoverSection.jpg');
  background-size: cover;
  background-position: right;
}

.makeover-heading {
  font-size: 46px;
  text-transform: capitalize;
}

.makeover-heading>div {
  font-family: "Good Times W00 Heavy", sans-serif;
  color: #fff;
  font-weight: bolder;
}

.makeover-heading>div span {
  font-family: "Good Times W00 Heavy", sans-serif;
  color: #4b3838;
  font-weight: 100 !important;
  -webkit-text-fill-color: #4b3838;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f5bd4f;
}

.makeover-logo {
  width: 290px;
}

.arrow_right_img {
  width: 28px;
}

.key-icon {
  width: 60px;
  height: 60px;
  background-color: #4B3838;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.fixed-propuser {
  position: fixed;
  right: 0;
  bottom: 20%;
  background-color: #f5c954;
  padding: 12px;
  padding-right: 20px;
  border-radius: 50px 0 0 50px;
  z-index: 1000;
  cursor: pointer;
}

.fixed-propuser .key-icon {
  width: 80px;
  height: 80px;
  background-color: #272423 !important;
}

.fixed-propuser .key-icon img {
  width: 60%;
}

.key-icon img {
  width: 45%;
}

.makeover-keyfeatures .line {
  height: 2.5px;
  width: 75px;
  background-color: #4b3838;
  border-radius: 5px;
}

.makeover-keyfeatures .line+h5 {
  color: #fff;
  font-size: 24px;
  margin-left: 20px;
}

.makeover-read-more {
  position: relative;
}

.makeover-read-more::after {
  content: '';
  display: block;
  position: absolute;
  width: 75%;
  height: 1.4px;
  background-color: #4b3838;
  border-radius: 4px;
  bottom: -10%;
  left: 0;
  opacity: .8;
}

.makeover-read-more:hover::after {
  background-color: #f5c954;
}

.translate-left {
  transform: translateX(-34px);
}

/* footer links sidebar */
.footer-siderbar {
  position: fixed;
  background-color: #252b2ee4;
  height: 100%;
  width: 280px;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 20px 20px 40px 40px;
}

.side-links>div {
  margin-top: 25px;
}

.side-links>div>a {
  color: #FFFFFF;
  text-decoration: none;
}

.side-links>div>a:hover {
  color: #F0B431;
}

/* suggestion-box */
.suggestion-box {
  position: absolute;
  padding: 15px;
  background-color: #faf4e4;
  top: 105%;
  left: 3%;
  width: 94%;
  max-height: 190px;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 5px;
}

.suggestion-box>div {
  background-color: #fff;
  padding: 5px 20px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 14px;
}

.suggestion-box>div:hover {
  background-color: #122844;
  color: white;
}

.suggestion-box>div.active_list {
  background-color: #122844;
  color: white;
}
/* market_popup */
.market_popup{
  position: absolute;
  top: 110%;
  right: -100%;
  background-color: #afaaaa;
  /* box-shadow: 0px 3px 6px #00000029; */
  padding: 0 30px 30px ;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 350px;
  border-radius: 15px;
}
.market_popup::after{
  content: "";
  position: absolute;
  top: -6px;
  right: 14%;
  background-color: #afaaaa;
  width: 20px;
  height: 20px;
  display:  inline-block;
  transform: rotate(45deg);
}
.market_popup .market_box {
  width: 75px;
  border-radius: 10px;
  margin-top: 35px;
}
.market_popup .market_box a{
  text-decoration: none;
}
.market_popup .market_box div{
  width: 75px;
  height: 75px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  background-color: #F5C954;
  margin: auto;
}
.market_popup .market_box div>i{
  font-size: 35px;
  color: #000000;
}
.market_popup .market_box p{
  font-weight: 600;
  color: #122844;
  text-align: center;
  text-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 0;
  line-height: 20px;
}
@media (max-width:768px) {
  .w-50.responsive-class {
    width: 100% !important;
  }

  .bd-slider-area {
    padding: 50px;
  }
}

@media screen and (max-width: 700px) {
  .makeover-box {
    height: auto;
  }

  .makeover-heading {
    font-size: 32px;
  }

  .makeover-heading>div span {
    font-family: "Good Times W00 Heavy";
    color: #4b3838;
    font-weight: 100 !important;
    -webkit-text-fill-color: #4b3838;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fbc352;
    font-weight: 900;
  }

  .translate-left {
    transform: translateX(0px);
  }

  .logg-size {
    width: 100%;
    padding-left: 0px;
  }

  .navbar {
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-top: 11px;
  }

  .bd-responsive-nav2 {
    width: 60%;
    margin-right: 30px;
  }

  .main-row {
    flex-wrap: wrap;
  }

  .bd-service-box-1,
  .bd-service-box-2 {
    width: 100%;
    /* margin-right: 0px;
    margin: 10px 15px; */
  }

  .textRight {
    text-align: center;
  }

  .display-flex {
    flex-wrap: wrap;
  }

  .bd-third-row {
    width: 50%;
  }

  .bd-top-cites {
    width: 44%;
  }

  .bd-section-footer1 {
    width: 50%;
  }

  .bd-basic-sizing {
    padding: 30px 0px 20px 20px;
  }

  .bd-section-footer2 {
    width: 100%;
  }

  .overlay {
    width: 100%;
    height: 100%;
  }

  .responsive-footer {
    display: none;
  }

  .bd-social-footer {
    display: block;
  }

  .bd-social-footer i {
    font-size: 26px;
    color: #122844;
  }

  .bd-text-policy {
    color: white
  }

  .bd-responsive-cities {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .main-head-banner {
    font-size: 35px;
    text-align: center;
    padding: 0 20px;
  }

  .bd-buttons-sell {
    justify-content: center;
  }

  .bd-home-search {
    width: 90%;
    margin: auto;
  }

  .sd-bodys {
    margin: 0px 16px;
  }

}

@media (max-width: 400px) {
  .makeover-logo {
    width: 240px;
  }
}

@media (max-width: 340px) {
  .makeover-logo {
    width: 200px;
  }
}