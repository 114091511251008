.prop-sold-box {
    width: 68%;
    margin: auto !important;
    background-color: #fff;
    padding: 90px 0;
}

.prop-sold-box div:first-child h2 {
    font-size: 35px;
    font-weight: 700;
    color: #122844;
}

.prop-sold-box div:first-child p {
    line-height: 30px;
}

.prop-sold-box div:last-child img {
    height: 350px;
    object-fit: cover;
}

.prop-sold-box div:first-child {
    transform: translateX(-24%);
}

.prop-sold-box div:last-child {
    transform: translateX(18%);
}

.prop-sold-box section input {
    font-size: 14px;
    outline: none;
    border: 1px solid #707070;
}

.prop-sold-box section input:focus {
    outline: 2px solid #F5C954;
}

.prop-top-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.prop-top-heading h3 {
    color: #122844;
    font-weight: 600;
    position: relative;
    font-size: 35px;
}

.prop-top-heading h3::before {
    content: '';
    display: inline-block;
    height: 2px;
    width: 70px;
    background-color: #F5C954;
    position: absolute;
    bottom: -10%;
    left: 0;
}

.prop-top-heading p {
    color: #444444;
    font-size: 15.5px;
    opacity: .8;
}

.bd-connect-button {
    background-color: #16a9ea;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 240px;
    padding: 10px;
}

.prop-assured img {
    height: 230px;
    object-fit: cover;
}

.prop-assured p {
    color: #444444;
    font-size: 15.5px;
    line-height: 25px;
}

.prop-assured h4 {
    font-size: 24px;
    font-weight: 600;
    color: #122844;
}

.line-yellow {
    height: 2px;
    background-color: #F5C954;
    width: 80px;
}

.selling-service-card p {
    font-size: 15.5px;
    line-height: 25px;
    /* font-weight: 300; */
}

.selling-service-card h3 {
    font-size: 24px;
}

.property-makeover-img {
    height: 460px;
    object-fit: cover;
}

.service-stack-list div {
    font-size: 15px;
    font-weight: 600;
    color: #444444;
    opacity: .8;
    border-left: 2px solid #F5C954;
}

@media (max-width: 768px) {
    .prop-sold-box {
        width: 90%;
        padding: 50px 0;
    }

    .prop-sold-box div:first-child h2 {
        font-size: 35px;
    }
    .prop-top-heading{
        align-items: flex-start;
    }
    .prop-top-heading h3{
        font-size: 25px;
    }
    .prop-top-heading p{
        font-size: 15px;
    }
    .prop-sold-box div:first-child {
        transform: translateX(0%);
        margin-bottom: 30px;
    }

    .prop-sold-box div:last-child {
        transform: translateX(0%);
    }
    .prop-sold-box div:last-child img {
        height: 250px;
        object-fit: cover;
    }

    .property-makeover-img {
        height: 320px;
    }
}