.makeover-header {
  background-image: url(../PagesUnderHome/makeover_header_img.png);
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.makeover-header h2 {
  line-height: 50px;
  font-size: 35px;
}

.makeover-header h2 div {
  color: #898989;
  font-weight: 400;
}

.makeover-header .key-icon {
  background-color: #515456;
  transform: scale(0.9);
}

.makeover-header .key-icon+div>a {
  color: #444444;
  font-size: 15px;
}

.makeover-header .key-icon+div>a::after {
  background-color: #f5c954;
}

.yellow-div {
  transform: translateX(50%) translateY(12%);
  width: 120px;
  position: relative;
  z-index: -1;
}

.makeover-center-logo {
  flex: 1;
  height: 460px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  background-image: url("../../assets/Career/prop_logo.png");
  background-size: contain;
}

.right-white-section {
  padding: 33px 100px 33px 60px;
  background-color: #fff;
  float: right;
  width: 670px;
}

.yellow-line-heading {
  font-size: 35px;
  font-weight: 600;
  color: #122844;
  display: flex;
  align-items: center;
}

.yellow-line-heading::before {
  content: "";
  width: 75px;
  background-color: #f5c954;
  height: 3px;
  display: inline-block;
  margin-right: 15px;
  border-radius: 5px;
}

.yellow-line-heading+div {
  padding-left: 90px;
  font-size: 14px;
}

.while-blue-btn {
  box-shadow: 1px 3px 6px #0000003a;
  color: #16a9ea !important;
  border-radius: 8px;
}

.makeover-benefit-card {
  height: 480px;
  padding: 10px 38px;
  background-size: cover;
}

.makeover-benefit-card .yellow-line-heading+div p {
  line-height: 26px;
  /* font-weight: 300; */
}

.makeover-benefit-card .benefit-card-content {
  min-height: 220px;
}

.two-round-image {
  height: 320px;
  text-align: right;
  position: relative;
}

.two-round-image>div {
  position: absolute;
  height: 260px;
  width: 50%;
  top: -13%;
  left: 0;
  background-color: #f5c954;
  border-radius: 90px 0 90px 0;
  z-index: -1;
}

.two-round-image>img {
  height: 100%;
  width: 91%;
  object-fit: cover;
  border-radius: 90px 0 90px 0;
}

.two-round-image-right {
  text-align: left;
}

.two-round-image-right>div {
  border-radius: 0px 90px 0px 90px;
  top: -13%;
  left: auto;
  right: 0;
}

.two-round-image-right>img {
  border-radius: 0px 90px 0px 90px;
}

.zigzag-content {
  width: 58%;
}

.zigzag-content>p {
  font-size: 15.5px;
  line-height: 25px;
}

/* join-video-section */
.join-video-section {
  margin-top: 100px;
  display: flex;
  align-items: end;
}

.video-container {
  padding: 0 8%;
  width: 100%;
}

.join-video-card>img {
  height: 230px;
  object-fit: cover;
  width: 100%;
}

.process-card {
  padding: 10px 25px;
  flex-direction: column;
  justify-content: space-between;
}

.process-card .process-card-content {
  min-height: 260px;
}

.process-card .yellow-line-heading {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.process-card .yellow-line-heading::before {
  margin-top: 15px;
}

.process-card .yellow-line-heading+div {
  padding-left: 75px;
  position: relative;
}

.process-card .process-icon {
  position: absolute;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  left: 2%;
  bottom: 99%;
  display: grid;
  place-items: center;
}

/* custom-accordian */
.custom-accordian {
  padding: 15px 40px 15px 135px;
}

.custom-accordian .paragraph {
  font-size: 15.5px;
  line-height: 25px;
}

.active-accordian {
  background-color: #f0f0f0;
}

.custom-accordian-btn {
  width: 23px;
  height: 23px;
  border: 1px solid #122844;
  color: #122844;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.custom-accordian-btn:hover {
  background-color: #122844;
  color: #fff;
}

.custom-accordian-btn span {
  line-height: 22px !important;
}

.frequently-question .yellow-div {
  transform: translateX(50%) translateY(-12%);
}

.frequently-question .right-white-section {
  width: 650px;
}

.yellow-line-heading.verticle-heading {
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  transform: rotate(180deg);
  text-transform: capitalize;
}

.yellow-line-heading.verticle-heading::before {
  height: 70px;
  width: 3px;
  margin-bottom: 20px;
  margin-right: 0px;
}

.readmore-popup {
  background-color: #f5c955;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0%;
  width: 450px;
  z-index: 10;
}

.curser-pointer,
.makeover-read-more {
  cursor: pointer;
}

.readmore-popup p {
  font-size: 12px;
}

.read-less-more-btn {
  background-color: transparent;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  border: 0;
  height: 50px;
  /* background-image: linear-gradient(transparent, #f2f2f2 90%); */
  font-size: 22px;
  font-weight: bold;
  color: #f5c955;
  text-transform: capitalize;
}


.all-services-arrow {
  animation: uoDown 1s infinite linear;
  display: inline-block;
  font-size: 30px;
  line-height: 10px;
}

.benefit-card-content .yellow-line-heading{
  font-size: 24px;
}

@keyframes uoDown {
  from {
    transform: translateY(-5px);
    opacity: .3;
  }

  to {
    transform: translateY(5px);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .yellow-line-heading {
    font-size: 25px;
  }

  .yellow-line-heading::before {
    content: "";
    width: 75px;
    background-color: #f5c954;
    height: 3px;
    display: inline-block;
    margin-right: 15px;
    border-radius: 5px;
  }

  .yellow-div {
    width: 50px;
  }

  .frequently-question .yellow-div {
    transform: translateX(50%) translateY(-12%);
  }

  .custom-accordian {
    padding: 15px;
  }

  .frequently-question .right-white-section {
    width: 100%;
    transform: translateY(0);
  }

  .makeover-center-logo {
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .right-white-section {
    padding: 20px;
    background-color: #fff;
    float: right;
    width: 100%;
  }

  .yellow-line-heading+div {
    padding-left: 0px;
    font-size: 14px;
  }

  .makeover-header > div {
    margin-top: 75%;
  }

  .makeover-header h2 div {
    line-height: 35px;
    margin-bottom: 20px;
  }

  .zigzag-content {
    width: 100%;
  }

  .two-round-image {
    height: 200px;
    text-align: right;
    position: relative;
  }

  .two-round-image>div {
    /* display: none; */
    height: 80%;
    top: -8.5%;
    left: -5%;
  }

  .two-round-image>img {
    width: 100%;
    /* border-radius: 90px 0 90px 0; */
  }

  .two-round-image-right>div {
    left: auto;
    right: -5%;
  }
  .readmore-popup {
    width: 100%;
  }
}