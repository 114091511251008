.city-section {
    width: 11.5%;
    margin-top: 5px;

}

.city-header {
    width: auto;
}

.city-header p {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #122844;
    display: inline-block;
}

.city-last-section {
    width: 135px;
    margin-top: 8px;
}

.top-cities {
    width: 90%;
    padding-left: 40px;
}

.top-cities>div {
    padding-left: 10px;
    padding-right: 0;
    width: 16.66%;
    border-left: 2px solid #f2be1a;
    margin-bottom: 25px;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .top-cities {
        width: 86%;
    }

    .top-cities>div {
        width: 14%;
    }
}

@media (max-width: 1000px) {
    .top-cities {
        width: 100%;
        padding-left: 0px;
    }

    .top-cities>div {
        width: 20%;
    }
}

.bd-section-2 {
    width: 15%;
}

.lead-section {
    width: 20%
}

.vertical-head {
    writing-mode: tb-rl;
    transform: rotate(-180deg);

}

.bd-col-md4 {
    width: 33.33%
}

.bd-service h2 {
    color: #444444
}

.box-resize {
    margin-right: 30px;
}

.bd-advantage-icon {
    width: 30%
}

.bd-service {
    padding: 30px 30px 50px 30px;
    border-radius: 30px;
    background-color: #FAF8F4;
    box-shadow: 0px 3px 6px #00000024;
}

.bd-advantage-section {
    max-height: 120px;
    color: #444444;
    overflow: auto;
    scrollbar-width: thin;
}

.bd-advan-border {
    border-top: 3px solid #F2BE1A;
    width: 100px;
}

.bd-faq-border {
    border-top: 3px solid #F2BE1A;
    width: 80px;
}

.bd-outer {
    padding-top: 19px;
}

.faq-card {
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px #00000038;
    border-radius: 20px !important;
    background-color: #FAF8F4 !important;
    padding: 14px 35px;
}

.faq-card .acc-desc {
    line-height: 28px;
}

.bd-homes-card {
    display: flex;
}

.card-header {
    border-bottom: none;
}

.btn-link-faq {
    color: #444444;
    font-weight: bold;
}

.btn-link-faq:hover {
    color: #444444;
}

.accordian-yellow-btn {
    width: 28px;
    height: 28px;
    background-color: #F2BE1A;
    border-radius: 50%;
    font-size: 15px;
    color: white;
    border: none;
    font-size: 22px;
    /* font-weight: bold; */
    padding: 0;
    line-height: 0;
}

.accordion-title {
    cursor: pointer;
}

.form-box {
    width: 1134px;
    height: 565px;
    box-shadow: 0px 3px 6px #0000002E;
}

.bd-contact-field-proprenuer input {
    background: #E8E8E8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: none;
    color: #122844;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    outline: none;
    padding: 0 20px;
    width: 330px;
    text-align: center;
}

.proprenuer-bd-btn {
    height: 40px;
    width: 200px;
    font-size: 13px
}

.multi-step {
    width: 25px;
    text-align: center;
    background-color: #16A9EA;
    border-radius: 50%;
    font-size: 16px;
    color: white;
}

.multi-step-un {
    width: 25px;
    text-align: center;
    background-color: #EFEFEF;
    color: #B8B8B8;
    border-radius: 50%;
    font-size: 16px;
}

.bd-continuation {
    border-top: 3px dotted #16A9EA;
    width: 60px;
    margin-top: 11px
}

.bd-continuation-un {
    border-top: 3px dotted #B8B8B8;
    width: 60px;
    margin-top: 11px
}

.bd-heading-detail {
    color: #fff;
    letter-spacing: 0.4px;
    text-shadow: 1px 1px 5px #11111174;
}

.main-searh-input-proprenuer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.bd-search-input-bar {
    width: 620px;
    height: 50px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: none;
    padding-left: 24px;
    color: #707070;
    outline: none;
    font-size: 16px;
}

.bd-search-button-bar {
    width: 55px;
    height: 52px;
    border: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: #122844;
}

.search-icons {
    color: rgb(242, 190, 26);
    font-size: 24px;
    font-weight: 300;
}

.bd-button-item {
    position: relative;
    left: -30px;
}

.bd-state-name {
    color: #122844;
    font-weight: 600;
    font-size: 35px;
}

.bd-city-name {
    font-size: 18px;
    color: #444444;
}

.bd-address-part {
    font-size: 14px;
    color: #444444
}

.bd-divider {
    font-size: 20px;
    font-weight: 600;
    color: #F2BE1A
}

.bd-infor-header-section1 {
    align-items: baseline
}

.bd-whole-infor-header {
    padding: 9px 14px;
    background-color: #122844;
    border-radius: 10px;
}

.dropdown-buttom {
    color: #fff !important;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}

.bd-number-pagination {
    color: #a0a9b4;
    cursor: pointer;
    font-weight: 500;
}

.bd-number-pagination1 {
    font-weight: 700;
}

.bd-total-entries {
    width: 74%;
    text-align: right;
}

.bd-text-pagination {
    font-size: 14px;
    color: #444444;
}

.dropdown-box::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 89% !important;
    z-index: -11;
    display: inline-block;
    width: 6%;
    height: 10px;
    background-color: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

}

.bd-dropdown-list {
    color: #444444;
    cursor: pointer;
    text-align: center;
}

.bd-dropdown-list:hover {
    color: #000;
}

.bd-dropdown-list.active {
    color: #122844;
    font-weight: 700;
}

.dropdown-box {
    margin-top: 7px;
}


/* top-cities-banner */
.top-cities-banner {
    background-size: cover;
    height: 86vh !important;
    background-position: center;
}

.top-cities-banner-half {
    height: 50vh !important;
}

.filter-select {
    /* width: 120px; */
    color: #454545;
    font-size: 19px;
    background-color: transparent;
    border: none;
}

.filter-select option {
    font-size: 16px;
    background-color: #122844 !important;
    color: #fff;
}

.filter-select.active-filter {
    font-weight: 600;
    color: #122844;
    border-bottom: 2px solid #f2be1a;
}

.propreneur-header-text span {
    color: #f2be1a;
    font-weight: 500;
}

/* Responsive design */
@media only screen and (min-width:330px) and (max-width:800px) {

    .top-cities-banner {
        height: 50vh !important;
        padding: 0 30px;
    }

    .navbar {
        /* padding-left: 15px;
        padding-right: 15px; */
        padding-top: 11px;
    }

    .logg-size {
        width: 100%;
        padding-left: 0px;
    }

    .bd-responsive-nav2 {
        width: 60%;
        margin-right: 30px;
    }

    .container1 {
        padding-top: 90px;
        height: 350px;
    }

    .overlay-image {
        width: 100%;
        height: 350px !important;
    }

    .overlay-image1 {
        width: 100%;
        height: 350px !important;
        object-fit: cover;
        background-position: center;
    }

    .bd-col-md4 {
        width: 93%;
    }

    .bd-service {
        margin-bottom: 20px;
    }

    .resp-section-advant {
        padding-top: 0px;
    }

    .bd-service-section-2 {
        margin-right: 0px;
    }

    .bd-outer {
        display: none;
    }

    .bd-responsive-section {
        padding-bottom: 30px;
    }

    .btn-link-faq {
        font-size: 12px;
    }

    .img-section {
        padding-top: 0px;
    }

    .main-faq-section {
        padding-top: 10px;
    }

    .pr-20 {
        padding-right: 20px;
        padding-bottom: 20px;
    }

    .bd-search-input-bar {
        width: 240px;
        height: 50px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border: none;
        padding-left: 15px;
        color: #707070;
    }

    .bd-section-2 {
        width: auto;
        padding-right: 10px;
    }

    .city-section {
        width: auto;
        margin-top: 5px;
        margin-right: 6px;
    }

    .bd-state-name {
        color: #122844;
        font-weight: 600;
        font-size: 20px;
        padding-left: 15px;
    }

    .bd-city-name {
        font-size: 15px;
        color: #444444;
    }

    .bd-infor-header-section1 {
        width: 100%;
        align-items: baseline;
    }

    .bd-number-pagination {
        width: auto;
        margin: 5px;

    }

    .bd-two-home-section-detail {
        width: 100% !important;
        padding-right: 0px !important;
        /* padding-bottom: 10px; */
    }

    .bd-homes-card-listing {
        margin-right: 0px;
    }

    .last-img {
        width: 50% !important;
        padding-right: 5px !important;
    }

    .overlay-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        box-sizing: border-box;
        padding-bottom: 100px !important;
        padding-top: 150px !important;
    }

    .mt-30 {
        margin-top: 100px !important;
    }

    .bd-text-policy {
        color: black;
        padding-top: 10px !important;
    }

    .textCenter .main-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .main-row-above {
        margin-left: 0% !important;
        margin-top: 0% !important;
    }

    .propreneur-banner {
        height: 50vh !important;
        padding-top: 50px;
    }
}

@media screen and (max-width: 600px) {
    .container1 {
        padding-top: 0px;
        height: auto;
    }

    .filter-select {
        width: 90%;
        margin: 10px auto;
        display: block;
    }
}

.propsection {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.propsection-img img {
    object-fit: cover;
    object-position: top;
    height: 430px;
    width: 100%;
}

.main-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background-color: rgb(51, 54, 55,0.8); */
    z-index: 1;
    border-radius: 5px;
}

.mainimage-container {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4a4c4ba4;
    height: 115px;
}

.color-white {
    color: white;
}

.color-yellow {
    color: #f2be1a;
}

.mainimage-container .icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2be1a;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    margin-top: 5%;
    cursor: pointer;
    position: absolute !important;
    right: 5%;
}

.mainimg-name {
    font-size: 22px;
    margin-bottom: 2px;
    font-weight: 500;
}

.email-phone {
    display: flex;
    align-items: center;
}

.email-phone i {
    margin-right: 10px;
}

.email-phone p {
    margin: 0;
    font-size: 15px;
}

.social-icons {
    position: absolute;
    bottom: 50%;
    left: 45%;
    transform: translateX(-75%) translateY(-25%);
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    transition: top 0.8s ease-in-out;
}

.propsection .icon-background:hover+.social-icons {
    top: 100%;
}

.social-icons i {
    margin: 5px 0;
    cursor: pointer;
    font-size: 20px;
}

.social-twitter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00ACEE;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 20%;
}

.social-instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff57e2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 20%;
}

.social-facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3b3ddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 20%;
}

.social-linkedin {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #397ad4;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: 20%;
}

.overlay-container {
    position: relative;
}

.overlay-image {
    width: 100%;
    height: auto;
}

.overlay-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
    box-sizing: border-box;
}

.overlay-section1 {
    position: relative;
}

.overlay-section1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 0;
}


.bg-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.85;
}

.main-row-above {
    margin-left: 10%;
    margin-top: -3%;
}

.overlay-container {
    position: relative;
}

.overlay-image1 {
    width: 100%;
    height: auto;
}

.Bio-Links{
    text-decoration: none;
    color: black;
}

.Bio-Links:hover{
    color: black;
}