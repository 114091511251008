.cost-accordian {
    border: 1px solid #143D63;
    border-radius: 6px;
    overflow: hidden;
}

.cost-accordian-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cost-accordian-header>div>button {
    display: block;
    height: 100%;
    width: 60px;
    font-size: 28px;
    /* font-weight: 600; */
    border: none;
    background-color: #F5C954;
    color: #fff;
}

/* accordian-table */
.accordian-table thead tr th{
    background-color: #E8E8E8;
    border: none !important; 
}
.accordian-table tbody tr:nth-child(even) td{
    border-right:  none !important;
    border-bottom: 1px solid #444;
    background-color: #F8F8F8;
}
.accordian-table tbody tr:nth-child(odd) td{
    background-color: #fff;
}

@media (max-width: 650px) {
    .cost-accordian-header > div div:first-child{
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    .cost-accordian-header > div div:last-child{
        font-size: 14px !important;
    }
    .cost-accordian-header > div > button{
        width: 45px;    
    }
    .accordian-table{
        width:  500px;
    }
}

@media (max-width: 352px) {
    .cost-accordian-header > div div:first-child{
        font-size: 12px !important;
    }
    .cost-accordian-header > div div:last-child{
        font-size: 12px !important;
    }
}