.custom-calendar .react-calendar{
  border: 0;
  background-color: #F8F8F8;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  padding: 10px;
}
.custom-calendar .react-calendar__navigation__prev-button,
.custom-calendar .react-calendar__navigation__next-button{
  font-size: 32px;
  color: #16A9EA;
  line-height: 0px;
}
.react-calendar__navigation__label__labelText--from{
  font-size: 16px;
  font-weight: 600;
  color: #122844;
}
.react-calendar__month-view__weekdays__weekday abbr{
}
.custom-calendar .react-calendar__tile--active:enabled{
  border-radius: 12px;
  background-color: #16A9EA !important;
}
.custom-calendar .react-calendar__month-view__days__day{
  border-radius:  12px;
  background-color: #F0F0F0;
  position: relative;
  font-weight: 500;
}
.custom-calendar .react-calendar__month-view__days__day--neighboringMonth{
  background-color: #f0f0f07e;
}
.custom-calendar .react-calendar__month-view__days{
  /* transform: scale(0.92); */
  justify-content: space-between;
}
.custom-calendar  .react-calendar__month-view__weekdays{
  /* gap: 1px; */
  justify-content: center;
}

.custom-calendar .react-calendar__month-view__days__day::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #F8F8F8;
  border-radius: 12px;
}