/* .container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

h1 {
  /* text-align: center; */
  color: #333;
}

.main-cont {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.label {
  display: flex;
  /* flex-direction: column; */
  /* gap: 5px; */
}

.label label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.label input[type='range'] {
  padding: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.label button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.label button:hover {
  background-color: #0056b3;
}

.res {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.range-slider-container {
  position: relative;
  width: 100%;
}

.range-slider-custom {
  width: 100% !important;
  -webkit-appearance: none;
  border-radius: 5px;
  border: none !important;
  cursor: pointer;
}

.range-labels {
  display: flex;
  position: relative;
  top: 6%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-100%);
  height: 20px;
}

.range-label {
  font-size: 11px;
  color: #444444;
  font-weight: 600;
  opacity: .6;
  position: absolute;
  top: 5%;
}

.range-labels .range-label:first-child {
  left: 0% !important;
}

.range-labels .range-label:last-child {
  left: 93% !important;
}

.range-value-box {
  width: 135px;
  text-align: center;
  position: relative;
}

.range-value-box input {
  width: 100%;
  outline: none;
  color: #122844;
}

.current-value {
  margin-top: 20px;
  text-align: center;
}
.rupee-symbol{
  position: absolute;
  background-color: #fff;
  top: 20%;
  padding-left: 8px;
  left: 1.5%;
  color: #122844;
  font-weight: 600;
}

/* Range Slider Styling */

.mortgage-calculator input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
}

/* Firefox */
.mortgage-calculator input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #dadada;
  border-radius: 1px;
}

.mortgage-calculator input[type="range"]::-moz-range-thumb {
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  height: 10px;
  width: 10px;
  border: 6px solid #f5c955 !important;
}

/* WebKit browsers (Chrome, Safari) */
.mortgage-calculator input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #dadada;
  border-radius: 1px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.mortgage-calculator input[type="range"]::-webkit-slider-thumb {
  background: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  -webkit-appearance: none;
  transform: translateY(-45%);
  border: 6px solid #f5c955 !important;
}

.mortgage-calculator input[type="range"]:focus {
  outline: none;
}

@media (max-width: 768px){
  .table.payment-schedule-table{
    width: 600px !important;
  }
}