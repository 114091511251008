.bd-vision-img {
  width: 63%;
}

.bd-vision-text {
  position: absolute;
  padding: 50px 75px 50px 60px;
  width: 40%;
  left: 0px;
  background-color: white;
}

/* Contact page */

.contact-form-image {
  position: absolute;
  display: none;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-form-contact {
  z-index: 10;
  padding: 50px 100px 0px 40px;
  border-radius: 20px;
  overflow: hidden;
}
.all-tag-contact input {
  border: none;
  border-bottom: 2px solid #a0a9b4;
  width: 100%;
  padding: 10px 0px 10px 0px;
  outline: none;
  font-size: 14px;
}

.all-tag-contact input:focus {
  border-bottom: 2px solid #f5c954;
}

.all-tag-contact input:focus::placeholder {
  color: #fff;
}

.all-tag-contact label {
  font-size: 14px;
  color: #444444;
  letter-spacing: 0.5px;
}

.bg-contact-img {
  background-size: cover;
  min-height: 110vh;
}

/* .bd-contact-page {

} */
.bd-select-option {
  width: 100%;
  background: #515456 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-size: 14px;
  font-weight: 550;
  appearance: none;
  scrollbar-width: none;
}

.bd-select-option2 {
  min-width: 180px;
}

.col-contact-6 {
  width: 50%;
  padding: 0px 18px;
  margin-bottom: 22px;
}

.contact_phone{
  position: absolute;
  left: 0;
  bottom: 26%;
}

.contact_phone2{
  bottom: 16%;
  left: 10px;
  font-weight: 500;
  color: #fff;
}

.col-contact-12 {
  width: 100%;
  padding: 0px 18px;
  margin-bottom: 35px;
}

.bd-contact-textarea {
  border: 1px solid #143d63;
  /* opacity: 0.4; */
  border-radius: 5px;
  padding: 15px;
  outline: none;
  font-size: 14px;
}

.bd-contact-textarea::placeholder {
  color: black;
}

.bd-contact-head h2 {
  font-weight: 600;
  font-size: 35px;
}

.bd-contact-submit {
  padding: 14px 80px;
  border: none;
  border-radius: 8px;
  background-color: #122844;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.bd-contact-details {
  transform: translateX(-18%) translateY(18%);
}


.bg-contact-detail-img {
  width: 83%;
  padding: 50px 70px 50px 40px;
  background-image: url("../../assets/footer/contact-detail.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.bd-detail-head h4 {
  color: #122844;
  font-weight: 700;
  font-size: 26px;
}

.bd-detail-head p {
  font-size: 15.5px;
}

.bd-contact-address-detail h5 {
  font-size: 18px;
  font-weight: 600;
}

.bd-contact-address-detail p {
  font-size: 14px;
}

.bd-icons-contact span {
  font-size: 19px;
}

.bd-icons-contact a {
  font-size: 19px;
  color: #122844;
  text-decoration: none;
}

.bd-inside-label {
  position: relative;
  top: 13px;
  background-color: white;
  z-index: 99;
  padding: 2px 7px 2px 0px;
  font-size: 14px;
  font-weight: 600;
}

/* Management Team */

.management-card {
  width: 50%;
}

.propenuer-polygon-image2 {
  width: 15em;
  height: 17.5em;
  object-fit: cover;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  object-position:top;
}

.bd-team-heading p {
  color: #444444;
}

.bd-content-side-team {
  width: 30%;
}

.bd-img-side-team {
  width: 40%;
}

.bd-contact-team-sec {
  padding: 5px 10px;
  border-top: 1px solid #f2be1a;
  border-bottom: 1px solid #f2be1a;
  color: #122844;
  flex-wrap: wrap;
}

.facebook-color {
  color: #3b5998;
}

.twitter-color {
  color: #10acee;
}

.instagram-color {
  color: #ce1451;
}

.linkedin-color {
  color: #0e76a8;
}

.bd-text-team {
  font-size: 15.5px;
  line-height: 24px;
  color: #333;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.bd-heading-team span:nth-of-type(1) {
  color: #f2be1a;
  font-size: 32px;
  border-bottom: 3px solid #f2be1a;
  font-weight: 550;
}

.bd-heading-team span:nth-of-type(2) {
  font-size: 32px;
  color: #122844;
  font-weight: 600;
}

.bd-main-heading-teams span:nth-of-type(1) {
  color: #f2be1a;
  font-size: 32px;
  font-weight: 550;
  border-bottom: 2px solid #f2be1a;
}

.bd-main-heading-teams span:nth-of-type(2) {
  color: #f2be1a;
  font-size: 32px;
  font-weight: 550;
}

.bd-main-heading-teams span:nth-of-type(3) {
  color: #122844;
  font-size: 32px;
  font-weight: 600;
}

.bd-responsive-textarea {
  display: none;
}

.team-card {
  /* flex-basis: 19%; */
  min-height: 380px;
  overflow: hidden;
  position: relative;
}

.team-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

.team-card h5 {
  font-size: 20px;
}

.team-card p {
  font-size: 14px;
  color: #7e7979;
}

.hover-team-content {
  font-size: 13.5px;
  font-weight: 500;
  /* display: none; */
  transition: all 0.5s;
  /* overflow: hidden; */
  position: absolute;
  background-image: linear-gradient(#faf4e4d4, #ffffff);
  width: 100%;
  height: 100%;
  left: 0%;
  bottom: -100%;
  transition: .4s;
  padding: 20px;
  color: #7e7979;
}
.hover-team-content p{
  font-size: 13px;
  color: #222;
}

.team-card:hover .hover-team-content {
  bottom: 0%;
}

@keyframes slideright {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.team-left-image {
  transform: translateX(-15%);
  position: relative;
  z-index: 3;
}

.team-right-image {
  transform: translateX(15%);
  position: relative;
  z-index: 3;
}

.main-team-box {
  height: 100%;
}

.manage-row {
  max-height: 222px;
}

/* for arrow line design for team */
.right-design-line {
  border-right: 2px dashed #f5c954;
  border-bottom: 2px dashed #f5c954;
  position: absolute;
  height: 58%;
  width: 14%;
  right: -33%;
  top: 3%;
  z-index: 4;
}

.left-design-line {
  border-left: 2px dashed #f5c954;
  border-bottom: 2px dashed #f5c954;
  position: absolute;
  height: 58%;
  width: 14%;
  left: -33%;
  top: 3.5%;
  z-index: 4;
}

.right-design-line::before,
.right-design-line::after,
.left-design-line::before,
.left-design-line::after {
  content: "";
  width: 22px;
  height: 24px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: inline-block;
  background-color: #f5c954;
  position: absolute;
  z-index: 5;
}

.right-design-line::before {
  right: 100%;
  bottom: -7%;
}

.right-design-line::after {
  right: -20%;
  top: -1%;
  width: 12px;
  height: 14px;
}

.left-design-line::before {
  left: 100%;
  bottom: -7%;
}

.left-design-line::after {
  left: -20%;
  top: -1%;
  width: 12px;
  height: 14px;
}

@media (max-width: 768px) {
  .w-md-70 {
    width: 100%;
  }

  .bd-contact-address-detail h5 {
    font-size: 14px;
  }

  .bd-contact-address-detail p {
    font-size: 13px;
  }

  .manage-row {
    max-height: fit-content;
    border-top: 2px solid #f5c954;
    margin-top: 60px;
  }

  .team-left-image {
    transform: translateX(-0%);
    position: relative;
    z-index: 3;
  }

  .team-right-image {
    transform: translateX(0%);
    position: relative;
    z-index: 3;
  }

  .management-card {
    width: 100%;
  }

  .team-leads {
    flex-direction: column;
  }

  .right-design-line {
    display: none;
  }

  .left-design-line {
    display: none;
  }

  .propenuer-polygon-image2 {
    width: 12em;
    height: 13.5em;
  }
}

/* ======================== */

@media (max-width: 600px) {
  .main-form-contact {
    width: 100%;
    background-image: none;
    background-color: white;
    padding: 40px 10px;
    margin: 0 10px;
    border-radius: 10px;
  }

  .col-contact-6 {
    width: 100%;
  }

  .col-contact-12 {
    width: 80%;
    padding: 0px 10px;
  }

  .bd-responsive-textarea {
    display: block;
    padding: 0px 18px 30px 18px;
  }

  .bd-pc-view {
    display: none;
  }

  .card-18 {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .bd-contact-details {
    width: 100%;
    transform: translateX(0%) translateY(0%);
    margin-top: 50px;
  }

  .bg-contact-detail-img {
    /* width:100% */
    background-image: none;
    background-color: #f5c954;
    padding: 40px 18px;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .bd-contact-details {
    margin-left: 0px;
  }

  .bd-text-resp {
    padding: 0 11px;
  }
  .team-card img {
    width: 100%;
    height: 320px;
  }
}

@media (max-width: 1200px) {
  .w-md-70 {
    width: 70%;
  }

  .manage-row {
    max-height: auto;
    min-height: 350px;
  }
}

@media (min-width: 1400px) {
  .w-md-70 {
    width: 70%;
  }

  .left-image-design {
    right: 3%;
  }

  .right-image-design {
    left: 3%;
  }
}

@media (min-width: 1720px) {
  .w-md-70 {
    width: 70%;
  }

  .left-image-design {
    right: 5%;
  }

  .right-image-design {
    left: 5%;
  }
}

@media (min-width: 768px) {
  .w-md-70 {
    width: 64%;
  }
}