@import url('https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.auto-container {
  position: static;
  max-width: 1210px;
  padding: 0px 15px;
  margin: 0 auto;
}

.auto-container-lg {
  position: static;
  max-width: 1290px;
  padding: 0px 15px;
  margin: 0 auto;
}

.loader-box {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ffffffb2;
  top: 0;
  left: 0;
  z-index: 99999;
}

.loader {
  width: 80px;
  aspect-ratio: 1;
  display: grid;
  border: 8px solid #0000;
  border-radius: 50%;
  border-color: #122844 #0000;
  animation: l16 1.2s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border-left: 8px solid #f5c954;
  border-right: 8px solid #f5c954;
  border-radius: 50%;
}

.loader::before {
  border-color: transparent #0000;
  animation: inherit;
  animation-duration: .6s;
  animation-direction: reverse;
}

.loader::after {
  margin: 10px;
}

@keyframes l16 {
  100% {
    transform: rotate(1turn)
  }
}

.bg-sky-blue {
  background-color: #16A9EA !important;
}

/* More Popup */
.modal-content {
  border-radius: 15px !important;
  overflow: hidden;
}

.modal-header {
  background-color: #f5c954;
  padding: 14px 28px !important;
}

.MoreCaregory {
  border: 1px solid rgba(211, 211, 211, 0.156);
}

.MoreCaregory:hover {
  background-color: #f5c954;
}

.shade-design {
  width: 65%;
  height: 120px;
}

.yellow-shade {
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background-color: #f5c954;
}

.blue-shade {
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background-color: #112945;
}

.soon-head {
  font-size: 110px;
  color: #f3f3f3;
}

.yellow-dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #f5c954;
}

.soon-line {
  width: 195px !important;
  height: 1px !important;
}

/* sitemap-section */

.common-height {
  min-height: 800px;
}

.sitemap-section h4 {
  font-size: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
  /* transform: translateX(70%); */
  color: #112945;
  font-weight: 600;
}

.sitemap-section .top-cities>div {
  width: 20%;
  background-color: #fff;
  padding: 10px;
}

.sitemap-section .top-cities>div>a {
  transition: all .4s;
}

.sitemap-section .top-cities>div>a:hover {
  color: #f5c954;
  font-weight: 600;
  padding-left: 12px;
}

.sitemap-section .key-icon {
  position: absolute;
  top: 100px;
  left: -2%;
}

@media (max-width: 600px) {
  .sitemap-section .top-cities>div {
    width: 100% !important;
  }

  .soon-head{
    font-size: 54px;
  }

  .sitemap-section h4 {
    font-size: 20px;
  }

  .auto-container-lg {
    max-width: 92% !important;
    /* padding: 0px 0px; */
  }
}

/* data comes from editor */

.editor_main_content div>h2 {
  color: #112945;
  font-weight: 600;
  font-size: 35px;
  position: relative;
  text-transform: capitalize;
}

.editor_main_content div>h3 {
  color: #112945;
  font-weight: 500;
  font-size: 30px;
  position: relative;
  text-transform: capitalize !important;
  line-height: 45px;
}

.editor_main_content div>h2::before,
.editor_main_content div>h3::before {
  content: '';
  background-color: #F2BE1A;
  height: 2px;
  width: 70px;
  position: absolute;
  left: 0;
  bottom: -2px;
  display: block;
  border-radius: 5px;
}

.editor_main_content div>p {
  line-height: 28px;
  font-size: 15.5px;
  color: #838383;
}

.leaflet-popup-tip-container {
  left: -6% !important;
  bottom: 50%;
  transform: rotate(90deg);
}

#property_map .leaflet-layer,
#property_map .leaflet-control-zoom-in,
#property_map .leaflet-control-zoom-out,
#property_map .leaflet-control-attribution {
  /* filter: grayscale(100%) invert(.1) ; */
}

.locality_map .leaflet-layer,
.locality_map .leaflet-control-attribution {
  filter: grayscale(100%) invert(.1);
}
.locality_map2 .leaflet-popup-tip-container{
  margin-left: -18px !important;
  display: none;
}
.locality_map2 .leaflet-popup{
  min-width: 189px;
}

/* popup */
#map .leaflet-popup-content {
  width: 190px !important;
  min-height: 100px;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

/* #map .leaflet-container {
  border-radius: 50%;
} */

#map .leaflet-popup-tip-container {
  left: -3.4% !important;
}


/* affordable filter */
.top-city-tab {
  cursor: pointer;
  color: #767874;
}

.active-city-tab {
  color: #122844 !important;
  font-weight: 600;
}

div h5.active-city-tab::before {
  content: '';
  background-color: #F2BE1A;
  height: 2px;
  width: 70px;
  position: absolute;
  left: 0;
  bottom: -6px;
  display: block;
  border-radius: 5px;
}

.lazy-load-image-background {
  width: 100% !important;
}

.slick-track {
  margin-left: 0 !important;
}

/* ==================================================== */
/* property */
.bg-dark-overlay {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: calc(100% - 90px);
  bottom: 0;
  left: 0;
  z-index: 9;
  display: none;
}

/* swal-text */
.swal-text{
  text-align: center;
}