.bd-main-service-section {
  display: flex;
  justify-content: center;
}

.bd-dashed-line {
  border-top: 3px solid #f2be1a;
  width: 60px;
  margin-top: 13px;
}

.bd-service-first-heading {
  font-size: 15.5px;
  color: #444444;
}

.bd-service-main-heading {
  color: #122844;
  font-weight: 700;
  font-size: 35px;
}

.bd-service-boxes {
  width: 90%;
  margin: auto;
}

.bd-service-box-1 {
  padding: 22px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  border: 1px solid #f0f0f0;
  background-color: white;
  transition: all .4s;
  min-height: 460px;
}

.bd-service-box-1:hover {
  transform: scale(1.06);
}

.home-property-type-image {
  height: 165px;
  border-radius: 18px;
  object-fit: cover;
  background-color: #f2f2f2;
  background-position: center;
}

.bd-search-btn {
  background-color: #122844;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  color: #f2be1a;
  cursor: pointer;
  /* font-size: 14px; */
  font-weight: 600;
  height: 35px;
  outline: none;
  overflow: hidden;
  position: relative;
  padding: 0px 25px;
}

.bd-card-head {
  color: #122844;
  font-weight: 700;
  font-size: 24px !important;
  text-wrap: wrap;
  display: block;
  max-height: 60px;
  overflow: hidden;
}

.bd-text-info {
  color: #505050;
  font-size: 14px;
  height: 120px;
}

.bd-service-rect {
  background-color: #faf8f4;
}

/* Cities Page */
.bd-third-row {
  width: 15%;
  text-align: center;
}

.bd-top-cites {
  width: 25%;
  padding: 10px 10px;
}

.bd-cities-back {
  background-image: url('../../assets/EasySearching.png');
  background-size: cover;
}