.bd-banner-authority {
    height: 110vh;
    width: 100%;
    position: relative;
}

.authority-banner {
    width: 100%;
    height: 105vh;
    position: relative;
}

.authority-banner::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../authoritycity.jpg");
    background-size: cover;
    background-position: left 30%;
    background-repeat: no-repeat;
    filter: grayscale(100%);
}

.authority-banner::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    position: absolute;
    height: 100%;
    width: 69%;
    left: 0;
    top: 0;
    z-index: 1;
}

.authority-banner-text {
    position: absolute;
    right: 12%;
    top: 0px;
    /* height: 133px; */
    width: 106%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    padding: 20px 35px;
    z-index: 10;
}

.authority-banner-text p {
    font-size: 32px;
    color: white;
}

.authority-banner-subtext {
    font-size: 15.5px !important;
}

.authority-banner-text span {
    color: #F6CA51;
    font-weight: 300;
}

.banner-authority-leftsection {
    width: 70%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    position: absolute;
    padding: 25px 25px;

}

.banner-map-div {
    height: 100%;
    width: 90%;
    position: absolute;
    left: 4rem;
}

.banner-map-div img {
    position: absolute;
}

.image1 {
    height:
        102.7px;
    width:
        138.36px;

    left:
        95.03px;


}

.image2 {
    height:
        58.73px;
    width:
        57.06px;
    left:
        150.06px;
    top:
        82.14px;
}

.image3 {
    height:
        59.18px;
    width:

        60.48px;
    left:

        114.62px;
    top:

        97.3px;
}


.authority-slider {
    width: 790px;
    position: absolute;
    bottom: 5%;
    left: -50%;
    z-index: 100;
    /* gap: 14px; */
    overflow: hidden;
}

.slider-container {
    border-radius: 12px;
    width: 250px;
    text-align: center;
    padding: 6px !important;
    background-color: #FAF4E4;
}

.slider-div {
    border-radius: 12px;
    padding: 12px 2px;
}

.active-slide {
    background-color: #F6CA51;
}

.active-container {
    border: 2px solid #F6CA51;
    background-color: transparent;
    border-radius: 15px;
}


.slider-div h5 {
    font-size: 15px;
    color:
        #132845;
    margin-bottom: 10px;
}

.slider-div p {
    font-size: 12px;
    color:
        #132845;
}

.section-authority {
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 93%;
    padding-top: 2rem;
}

.section-authority span {
    font-size: 13px;
}

.section-authority h6 {
    color:
        #F6CA51;
    font-size: 15px;
}

.search-district::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: #132845 !important;
}

.authority-searchbar {
    width: 320px;
}


.authority-searchbar .button {
    height: 40px;
    width: 40px;
    background-color: #F6CA51;
    padding-left: 9px;
    padding-right: 5px;
    padding-top: 6px;
    border: transparent;
    border-radius: 8px;

}

.authority-searchbar i {
    color: #132845;
}

.authority-cards-section {
    margin-top: 35px;
}

.authority-cards-section p {
    font-size: 24px;
    color: #F6CA51;
}

.authority-cards-section span {
    font-size: 24px;
    color: #000;
    font-weight: 700;

}

.authority-cards-section .card {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 12px;
    background-color: #FAF4E4;
    transition: all 0.3s ease-in;
}

.active-tahsil {
    outline: 2px solid #F6CA51;
    border-radius: 15px;
    padding: 4px !important;
}

.active-card {
    background-color: #F6CA51 !important;
}

.authority-cards-section .card {
    border: 1px solid #FAF4E4;
}

.authority-cards-section .card h6 {
    font-size: 15px;
    color: #132845;
    font-weight: 600;
}

.authority-cards-section .card p {
    font-size: 13px;
    color: #132845;
    margin-bottom: 0 !important;
}

.authority-table-section {
    margin-top: 45px;
}

.authority-table-section p {
    font-size: 24px;
    color: #F6CA51;
}

.authority-table-section span {
    font-size: 24px;
    color: #000;
    font-weight: 700;

}

.tehsil-table {
    width: 30% !important;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid #132845 !important;
    border-bottom: none !important;
    overflow: hidden;
}

.table th:not(:last-child),
.table td:not(:last-child) {
    border-right: 1px solid #132845;
}

.table-header tr {
    background-color: #132845 !important;
}

.table-header th {
    background-color: #132845;
    color: white;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 20px;
    border: none;
}

.table-header th:first-child {
    border-top-left-radius: 12px !important;
}

.table-header th:last-child {
    border-top-right-radius: 12px !important;
}

.table-body tr {
    background-color: white;
}

.table-body td {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    color: #132845;
    border-bottom: 1px solid #132845;
}

@media(max-width:900px) and (min-width:800px) {
    .authority-banner-text {
        right: 90px;
    }

    .banner-map-div {
        top: 16rem;
        left: 10rem;
    }

    .authority-slider {
        bottom: 120px;
        left: 3rem;
    }
}

@media(max-width:768px) {
    .authority-banner-text {
        width: 100%;
        height: auto;
        position: relative;
        left: 0;
    }

    .indian-map {
        padding-top: 10px;
    }

    .indian-map svg {
        position: static !important;
    }

    .authority-cards-section p {
        font-size: 22px;
    }

    .authority-cards-section span {
        font-size: 22px;
    }

    .authority-cards-section .card {
        padding-left: 5px;
        padding-right: 5px;
    }

    .authority-cards-section .card p {
        font-size: 12px;
    }

    .tehsil-table {
        width: 100% !important;
    }



    .slider-div {
        width: 100%;
    }

    .banner-authority-slider {
        width: 100%;
        right: 25px;
    }

    .authority-slider {
        width: 100%;
        bottom: 5%;
        left: 0%;
    }

    .banner-map-div img {
        width: 280px;
        bottom: 8rem;
    }

    .authority-slider {
        bottom: 90px;

    }

    .banner-map-div {
        bottom: 14rem;
        left: 5rem;
    }

    .bd-banner-authority {
        height: 140vh;
    }

    .authority-banner {
        height: 100vh;
    }

    .banner-map-div {
        bottom: 15rem;
    }

    .authority-slider {
        bottom: -50%;
        padding-left: 50px;
    }

}

@media(max-height:670px)and (max-width:400px) {
    .banner-map-div {
        bottom: 10rem;
    }
}