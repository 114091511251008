.react-tagsinput {
    border-radius: 25px;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: 45px !important;
    display: flex;
    align-items: center;
    padding: 0 !important;
    max-width: 100% !important;
    overflow-y: scroll !important;
    scrollbar-width: none;
    position: relative;
}

.react-tagsinput > span:first-of-type {
    position: absolute;
    top: 0%;
    min-height: 100%;
}
  


.react-tagsinput--focused{
    border: none !important;
    outline: none !important;
}

.react-tagsinput-input {
    /* height: 45px; */
    padding-left: 20px;
    font-size: 16px !important;
    border : none;
    border-radius: 25px;
    color: black !important;
    outline: none;
    margin: 0px 0px 0px 10px !important;
}

.react-tagsinput--focused .react-tagsinput-input:active{
    border: none !important;
    outline: none !important;
}

.react-tagsinput-tag{
    background-color: #ddd !important;
    color: #000 !important;
    padding: 5px 10px !important;
    border: none !important;
    font-size: 15px !important;
    border-radius: 15px !important;
    margin: 5px 0px 5px 10px !important;
    text-transform: capitalize;
}

.react-tagsinput-remove{
    padding-left: 5px !important;
    text-decoration: none !important;
    color: black !important;
}

.Search-Button-Home{
    position: absolute;
    right: 0%;
    top: 0%;
}

.Search-Button-Home .bd-search-button-bar{
    height: 45px !important;
    width: 45px !important;
}

.Search-Button-Home .bd-search-button-bar .search-icons{
    height: 22px !important;
}

.NavbarSearch{
    border: 1px solid black;
}

.small-input {
    width: 80px !important;
    border: none;
    margin-left: 10px;
}

.large-input {
    width: 350px !important;
    line-height: 42px;
    border: none;
    margin-left: 10px;
}

.react-tagsinput--focused input{
    border: none !important;
    outline: none !important;
}


@media (max-width : 768px) {

    .react-tagsinput{
        min-width: fit-content !important
    }
    
}

.Clear-Button{
    position: absolute;
    right: 50px;
    top: 20%;
}

.justified-text{
    text-align: justify;
}