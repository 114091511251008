.bd-SeeAll-btn {
  background-color: #122844;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  color: #f2be1a;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  height: 35px;
  outline: none;
  overflow: hidden;
  position: relative;
  padding: 0px 60px;
}

.bd-cites-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url("../../assets/Neighbourhood01.jpg");
  background-size: fill !important;
  background-repeat: repeat !important;
}

.city-image-box {
  width: 100%;
  height: 220px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.city-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.city-image-box:hover .city_name {
  /* opacity: 1; */
  padding-bottom: 21px;

}

.top-city-box {
  height: 300px;
}

.top-city-box .city_name {
  font-size: 19px;
  text-transform: uppercase;
  padding: 24px;
  padding-bottom: 0;
}

.city_name {
  position: absolute;
  bottom: 0%;
  left: 0%;
  color: #f2be1a;
  background-color: #00000015;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: end;
  padding: 20px;
  padding-bottom: 21px;
  opacity: 1;
  transition: all .4s;
  text-shadow: 1px 1px 2px #122844;
}

.bd-slider-area {
  /* padding: 85px 80px; */
  height: 100%;
  background-color: #122844;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bd-slider-area>div {
  width: 70%;
}



.bd-testimonial-head {
  color: #f2be1a;

}

.bd-inverted-coma {
  font-size: 97px;
  height: 67px;
  margin: 0px;
  color: #f2be1a;
}

.testimonial-card {
  height: 460px;
}

.testimonial-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.review-play-btn {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  background-color: #F5C954;
  width: 60px;
  height: 60px;
  border: none;
  font-size: 40px;
  color: #fff;
}

.review-volume-btn {
  position: absolute;
  top: auto;
  top: 0 !important;
  right: 0 !important;
  background-color: transparent;
  width: 60px;
  height: 60px;
  border: none;
  font-size: 30px;
  color: #fff;
}

.bd-banner-home {
  background-image: url("../../assets/HeroBanner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
  display: flex;
  align-items: center;
}

.bd-banner-section {
  display: flex;
  margin: auto;
  max-width: 55%;
  justify-content: center;
  /* padding: 210px 0px; */
}

.main-head-banner span {
  color: #f2be1a;
  font-weight: 300;
}

.main-head-banner {
  font-size: 50px;
  color: white;
}

.bd-buy-capsule {
  padding: 6px 35px;
  text-decoration: none;
  color: black;
  background-color: #fff;
  border-radius: 25px;
  font-size: 14px;
}

.bd-active-capsule {
  background-color: #F2BE1A;
}

/* .bd-buy-capsule:hover {
  color: #f2be1a;
} */
.bd-category {
  color: #f2be1a;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.bd-line-side {
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 500;
}


.slider-btns button {
  padding: 10px 20px;
  background-color: #f5c954;
  font-size: 26px;
  border: none;
}

.slider-btns button:hover {
  color: #fff;
}

.slider-btns button:active {
  transform: scale(1.04);
}

/* pagination buttons */
.pagination-btn {
  width: 35px;
  height: 30px;
  background-color: #122844;
  color: #fff !important;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 15px;
}

.activepage {
  background-color: #f5c954;
  font-weight: 600;
  color: #122844 !important;
}

.opacity-50 {
  opacity: .7;
}

.neighbour-city-design div.content {
  background-color: #1111116f;
}

@media screen and (max-width: 768px) {

  .bd-banner-section{
    width: 95%;
    max-width: 95%;
  }
  
  .bd-slider-area>div {
    width: 100%;
  }

  .testimonial-card {
    height: auto;
  }

  .responsive-class img.testimonial-img {
    height: 400px !important;
  }

  .bd-banner-home {
    min-height: 80vh;
  }
}