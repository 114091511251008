.join-proprenuer-bg {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../authoritycity.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.join-header-slider-tabs {
  position: absolute;
  width: 20%;
  left: 4%;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.join-header-slider-tabs li {
  color: #827b7a;
  text-transform: capitalize;
  transition: all 0.3s;
}

.join-header-slider-tabs li:hover {
  color: #f5c954;
  font-weight: 500;
  cursor: pointer;
}

.join-header-slider-tabs li.tab-active {
  color: #f5c954;
  font-weight: 500;
}

.join-header-slider-tabs li::marker {
  color: #f5c954;
}

.join-header-slider-tabs ul li {
  padding: 4px 10px;
}

.join-middle-content {
  width: 600px;
  margin: auto;
  overflow: hidden;
  background-color: transparent;
}

.join-middle-content .logo {
  width: 220px;
  margin: auto;
}

.join-button {
  padding: 9px 45px;
  border: 1px solid #f5c954;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 500;
  background-color: transparent;
  font-size: 16px;
  transition: all 0.3s;
}

.join-button:hover {
  background-color: #f5c954;
}

.join-button i {
  transform: translateY(4px);
  display: inline-block;
  color: #000;
}

.yellow-verticle-line {
  width: 2px;
  margin: 14px auto;
  background-color: #f5c954;
}

.yellow-verticle-line.small {
  height: 40px;
}

.yellow-verticle-line.middle {
  height: 65px;
}

.yellow-verticle-line.large {
  height: 100px;
}

/* nation-best-propnuer */
.nation-best-propnuer div>h4 {
  font-size: 39px;
  font-weight: 600;
}

.white-content {
  box-shadow: 2px 2px 5px #999999bd;
}

.nation-best-propnuer .white-content {
  width: 650px;
  padding: 35px;
  background-color: #fff;
  position: relative;
  z-index: inherit;
}

.right-white-content {
  float: right;
}

.slider-btns-2 {
  position: absolute;
  right: 28px;
  bottom: -30px;
  z-index: 10;
}

.nation-best-propnuer .col-md-7>img {
  height: 610px;
  object-fit: cover;
}

.text-dark-light {
  color: #444444 !important;
}

.join-middle-content .propuser-large-text {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #f2f2f2;
  font-size: 180px;
  font-weight: 900;
  position: absolute;
  bottom: 10%;
  left: 4%;
  opacity: 0.3;
  letter-spacing: 6px;
  line-height: 0 !important;
  width: 100%;
}

.nation-best-propnuer .propuser-large-text {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #f2f2f2;
  font-size: 180px;
  font-weight: 900;
  position: absolute;
  bottom: 6%;
  left: -2%;
  opacity: 0.7;
  letter-spacing: 6px;
  line-height: 0;
}

.nation-best-propnuer2 .propuser-large-text {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #f2f2f2;
  font-size: 190px;
  font-weight: 900;
  position: absolute;
  bottom: 2%;
  left: auto;
  right: 0%;
  opacity: 0.7;
  letter-spacing: 9px;
  line-height: 0;
}

.show-top {
  position: relative;
  z-index: 100;
}

.para-quote {
  position: absolute;
  top: -30%;
  left: -2.9%;
  z-index: -1;
  line-height: 0;
}

/* media queries */
@media (max-width: 768px) {
  .join-middle-content {
    width: 100%;
  }

  .nation-best-propnuer div>h4 {
    font-size: 25px;
    font-weight: 600;
  }

  .nation-best-propnuer .white-content {
    width: 100%;
    padding: 25px;
  }

  .nation-best-propnuer .col-md-7>img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (min-width: 768px) {
  /* .text-md-indent-5 {
    text-indent: 5%;
  } */
}

/* New ===================================================================================== */
.join-first-sec-container{
  max-width: 1340px;
  margin: 0 auto;
}
.join-first-sec-image {
  height: 620px;
  transform: rotate(-40deg) translatex(130px) translateY(-50px);
  border-radius: 50rem 0 0 55rem;
  text-align: right;
}

.join-first-sec-image>img {
  height: 602px;
  width: 99%;
  border-radius: 50rem 0 0 55rem;
  transform: rotate(0deg);
  object-fit: cover;
}

/* real-state circles */
.real-first-dark-circle {
  width: 70px;
  height: 70px;
  background-color: #122844;
  top: 10%;
  left: 13%;
}

.real-second-yellow-circle {
  width: 140px;
  height: 140px;
  background-color: #F5C954;
  bottom: 22%;
  left: 10%;
}

.real-third-blue-circle {
  width: 50px;
  height: 50px;
  background-color: #16A9EA;
  bottom: 15%;
  right: 38%;
}

.real-fourth-yellow-circle {
  width: 200px;
  height: 200px;
  background-color: #F5C954;
  bottom: 20%;
  right: 3%;
}

.real-fifth-light-circle {
  width: 180px;
  height: 180px;
  background-color: #F7F7F7;
  bottom: -2%;
  left: -5%;
}

.real-sixth-dark-circle {
  width: 25px;
  height: 25px;
  background-color: #122844;
  bottom: 20%;
  left: 9%;
}

.real-seventh-yellow-circle {
  width: 15px;
  height: 15px;
  background-color: #F5C954;
  top: 12%;
  left: 4%;
}

.real-eighth-blue-circle {
  width: 200px;
  height: 200px;
  background-color: #16A9EA;
  top: -11%;
  left: -11%;
}

/* ====================== */
.lead-magnet-card {
  height: 170px;
  background-color: #F5C954;
}

.magnet-left-card {
  transform: translateY(8px);
}

.magnet-right-card {
  transform: translateY(-8px);
}

.magnet-img {
  width: 250px;
  height: 100%;
}

.magnet-img > img{
  object-fit: cover;
  object-position: center;
}

.magnet-content {
  display: grid;
  place-items: center;
  background-color: #FAF8F4;
  color: #444444;
  font-weight: 500;
  font-size: 15.5px;
}
.magnet-content *{
  background-color: transparent !important;
  color: #444444 !important;
}
.sky-blue-btn {
  width: 240px;
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  background-color: #16A9EA;
  font-weight: 500;
}

/* advantage section */
.advantage-card {
  min-height: 390px;
  padding: 30px 30px 20px 30px;
}

.advantage-card h2 {
  font-size: 24px;
  margin-bottom: 15px;
  padding-left: 15px;
  position: relative;
  color: #444444;
  font-weight: 600;
}

.advantage-card h2>div {
  position: absolute;
  width: 3px;
  height: 35px;
  left: 0;
  top: 0;
}



.advantage-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 8px solid #000;
  display: grid;
  place-items: center;
}

/* Diversity */
.diversity-bg {
  background-image: linear-gradient(#020912bb, #020912bb), url('https://plus.unsplash.com/premium_photo-1673014202349-38687dd47f94?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

.diversity-card {
  position: relative;
}

.diversity-card h3 {
  font-size: 20px;
  color: #F5C954;
  margin-bottom: 18px;
  font-weight: 600;
}

.diversity-card p {
  color: #fff;
}

.diversity-card-left {
  text-align: right;
}

.diversity-card-right {
  text-align: left;
}

.diversity-card::after {
  content: '';
  height: 35px;
  width: 3px;
  background-color: #F5C954;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 5px;
}

.diversity-card-left::after {
  right: 0;
}

.diversity-card-right::after {
  left: 0;
}

.middle-design {
  background-color: #d1d2d2;
  text-align: center;
  min-height: 700px;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.middle-design>div img {
  width: 72%;
  height: 600px;
  object-fit: contain;
}

.middle-design-lines {
  position: relative;
}

.middle-design-lines::after,
.middle-design-lines::before {
  content: '';
  height: 300px;
  width: 8px;
  background-color: #F5C954;
  display: inline-block;
  position: absolute;
  border-radius: 5px;
  transform: rotate(5.5deg);
}

.middle-design-lines::before {
  top: 1%;
  left: 7%;
}

.middle-design-lines::after {
  bottom: 1%;
  right: 7%;
}

/* propreneur glance */

.join-round-image {
  padding: 40px;
  background-color: #FAF8F4;
  border-radius: 50%;
  position: relative;
  z-index: 100 !important;
}

.join-round-image>img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.join-small-round-image {
  width: 480px;
  height: 480px;
  transform: translateX(-40px);
}

.join-big-round-image {
  width: 850px;
  height: 850px;
  transform: translateX(50px);
}

.join-circle-connector {
  width: 300px;
  height: 230px;
  background-color: #FAF8F4;
  position: absolute;
  z-index: 0;
  left: 70%;
  top: 20%;
  transform: rotate(-10deg);
  overflow: hidden;
}

.join-circle-connector::after {
  content: '';
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: -20%;
  left: 0%;
  background-color: #fff;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: rotate(8deg);
}

.join-circle-connector::before {
  content: '';
  display: block;
  width: 82%;
  height: 100px;
  position: absolute;
  top: -20%;
  left: 9%;
  background-color: #fff;
  border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: rotate(0deg);
}

/* Circles for designs */
.join-first-purple-circle {
  width: 20px;
  height: 20px;
  background-color: #675388;
  top: 10%;
  left: 15%;
}

.join-second-yellow-circle {
  width: 200px;
  height: 200px;
  background-color: #F5C954;
  top: 18%;
  left: 2%;
  z-index: -1;
}

.join-third-purple-circle {
  width: 40px;
  height: 40px;
  background-color: #675388;
  bottom: 18%;
  left: 6%;
}

.join-fourth-light-circle {
  width: 180px;
  height: 180px;
  background-color: #FAF8F4;
  bottom: -8%;
  left: -0%;
}

.join-fifth-yellow-circle {
  width: 40px;
  height: 40px;
  background-color: #F5C954;
  bottom: -6%;
  right: 20%;
}

.join-sixth-purple-circle {
  width: 20px;
  height: 20px;
  background-color: #675388;
  bottom: -6%;
  right: 15%;
}

.join-seventh-blue-circle {
  width: 90px;
  height: 90px;
  background-color: #16A9EA;
  bottom: -2%;
  right: 7%;
  z-index: 100;
}

.join-eighth-dark-blue-circle {
  width: 90px;
  height: 90px;
  background-color: #122844;
  bottom: 0%;
  right: 22%;
  z-index: 100;
}

.join-ninth-light-circle {
  width: 40px;
  height: 40px;
  background-color: #F7F7F7;
  top: 12%;
  left: 28%;
}

.join-tenth-light-circle {
  width: 180px;
  height: 180px;
  background-color: #F7F7F7;
  top: -8%;
  left: -15%;
  z-index: 100;
}


/* Key benefits */
.join-key-benefits-bg {
  background-image: url('../../assets/ProprenuerPage/bg-key-benefits.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.join-key-benefits-bg>div>div {
  padding-right: 120px;
}

.key-purple-box {
  width: 200px;
  height: 200px;
  background-color: #675388;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transform: rotate(50deg);

}

.key-purple-box h3 {
  color: #F5C954;
  font-size: 40px;
  font-weight: 600;
}

.key-purple-box p {
  font-size: 15px;
}

.join-gradient-box {
  padding: 25px;
}

.gradient-left {
  background-image: linear-gradient(to left, #ebe8e8 0%, transparent 100%);
  border-radius: 0 200px 200px 0;
}

.gradient-right {
  background-image: linear-gradient(to left, transparent 0%, #ebe8e8 100%);
  border-radius: 200px 0 0 200px;
}

.join-rotate-box {
  transform: rotate(-50deg);
}

@media (max-width: 768px) {
  .advantage-card {
    padding: 20px;
  }

  .advantage-icon {
    width: 70px;
    height: 70px;
  }

  .faq-card {
    padding: 14px 20px;
  }

  .join-rotate-box,
  .key-purple-box {
    transform: rotate(0deg);
  }

  .key-purple-box {
    width: 160px;
    height: 160px;
  }

  .join-key-benefits-bg>div>div {
    padding-right: 0px;
  }

  .key-purple-box h3 {
    font-size: 28px;
  }

  .key-purple-box p {
    font-size: 14px;
  }

  .join-round-image {
    padding: 20px;
  }

  .join-small-round-image {
    width: 280px;
    height: 280px;
    transform: translateX(0px);
    margin: auto;
  }

  .join-big-round-image {
    width: 320px;
    height: 320px;
    margin: auto;
    transform: translateX(0px);
  }

  .join-circle-connector {
    display: none;
  }

  .join-fourth-light-circle {
    left: 15%;
    bottom: -15%;
  }

  .join-tenth-light-circle {
    width: 130px;
    height: 130px;
    top: -8%;
    left: -15%;
  }

  .join-ninth-light-circle {
    width: 40px;
    height: 40px;
    background-color: #F7F7F7;
    top: 15%;
    left: 8%;
  }

  .join-key-benefits-bg {
    background-image: none;
  }

  .lead-magnet-card.rounded-pill {
    height: auto;
    border-radius: 20px !important;
    /* overflow: hidden; */
  }

  .lead-magnet-card .magnet-left-card,
  .lead-magnet-card .magnet-right-card {
    transform: translateY(10px) translateX(10px);
    border-radius: 20px !important;
  }

  .magnet-img {
    width: 100%;
  }

  .real-eighth-blue-circle {
    display: none;
  }

  .real-seventh-yellow-circle {
    top: 10%;
    left: 80%;
  }

  .join-first-sec-image {
    height: 350px;
    width: 400px;
    transform: rotate(-40deg) translatex(40px) translateY(-20px);
    border-radius: 50rem 0 0 55rem;
    text-align: right;
  }

  .join-first-sec-image>img {
    height: 340px;
    width: 400px;
  }

  .left-side-circles>div {
    display: none;
  }

  .real-fourth-yellow-circle {
    width: 60px;
    height: 60px;
    bottom: 10%;
  }

  .real-third-blue-circle {
    width: 40px;
    height: 40px;
    bottom: 4%;
  }

  .real-second-yellow-circle {
    width: 80px;
    height: 80px;
    bottom: 20%;
    left: 0%;
  }

  .real-first-dark-circle {
    width: 50px;
    height: 50px;
    left: 10%;
  }
}