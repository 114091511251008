.main-logo {
  height: 57px;
}

.dropdown-content {
  position: absolute;
  background-color: white !important;
  min-width: 200px;
  z-index: 1;
  /* -webkit-border-radius: 10px;
  -moz-border-radius: 10px; */
  border-radius: 10px;
  right: 0;
  padding: 5px 0;
}

.dropdown-content:before {
  content: "";
  position: absolute;
  top: -11px;
  right: 18px;
  z-index: 1;
  width: 25px;
  height: 15px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.dropdown-content a {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}


.logg-size {
  width: 30%;
  padding-left: 10px;
  opacity: 100%;
}

.side {
  font-size: 18px;
}


.bg-grey {
  background-color: #515256;
  /* opacity:0.8; */
}

.bg-grey-transparent {
  background-color: #292929c7;
  /* opacity: 0.8; */
}

.navbar-brand {
  width: 55%
}

.nav-link {
  font-size: 17px;
  font-weight: 520;

}

.icon-size {
  width: 23px;
}

.flex-size-header2 {
  width: 15%;
  margin-right: 10px;
}

.nav-link1 {
  padding-top: 5px;
}

.main-searh-input {
  width: 95% !important;
  border-radius: 5px;
  overflow: hidden;
  margin: auto;
}

/* logout */
.profile-img img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.profile-popup.active {
  font-weight: 700;
}

.logout-dropdown {
  right: 0;
  top: 110%;
  min-width: 180px;
}

.logout-dropdown a {
  font-size: 14px;
}



@media screen and (max-width: 800px) {
  .navbar-brand {
    width: 142px !important;
  }
}

/* only screen and (min-width:350px) and */
@media (max-width:550px) {
  .top-cities>div {
    width: 33% !important;
  }

  .logg-size {
    width: 70%;
    padding-left: 0px;
  }

  .toggle-btn {
    /* margin-right: 20px; */
  }

  .flex-size-header2 {
    width: 45%
  }

  .nav-responsive {
    display: none;
  }

  .toggle-btn {
    margin-top: 2px;
  }

  .icon-size {
    width: 10%;
  }

  .responsive-bord {
    margin-top: 15px;
  }

  .nav-link {
    border-top: 1px solid #F2BE1A;
  }

  .bd-search-navbar {
    width: 100%;
  }

  .input-group {
    width: 100%
  }
}

@media (max-width:450px) {
  .top-cities>div {
    width: 50% !important;
  }
}

@media (max-width: 350px) {
  .main-logo {
    height: 47px;
  }
}

@media screen and (max-width: 1111px) {
  .bd-search-navbar {
    width: 25%;
    margin-left: 20px;
  }

  .main-searh-input {
    width: 100% !important;
  }
}