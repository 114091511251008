#Locality-new {
  .card .row span {
    font-size: 13.5px;
  }

  .know-about-city {
    color: #122844 !important;
  }

  .col-md-70 {
    width: 70%;
    flex: 0 0 auto;
  }

  .col-md-30 {
    width: 30%;
    flex: 0 0 auto;
  }
}

.locality-dots img {
  height: 200px;
  width: 50px;
  object-fit: cover;
}

.bd-main-section-map {
  height: 500px;
  position: relative;
  z-index: 999;
}

.locality_map_detail {
  position: absolute;
  left: 5%;
  bottom: 8%;
  z-index: 1000;
  border-radius: 15px;
}

.locality_map_detail2 {
  position: absolute;
  right: 5%;
  bottom: 8%;
  z-index: 1000;
  border-radius: 15px;
}

.points-10 h3 {
  font-weight: 600 !important;
  font-size: 1.5rem;
}

.points_desc p {
  margin-bottom: 0;
}

.points_img_desc * {
  margin-bottom: 0;
}

.buttons-grroup button {
  background-color: #122844;
  border-radius: 0.5rem !important;
  font-weight: 500;
  font-size: 17px;
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.top-card {
  position: absolute;
  transform: translateY(-50%);
  background-color: #f5c954;
  border-radius: 2px;
}

.top-card-2 {
  right: 0;
}

.top-card h3 {
  color: #122844 !important;
  font-weight: 600;
  margin-top: -6%;
  font-size: 35px;
}

.top-card .card p {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
}

.overlay-card .overlay-n {
  background-color: black;
  border-radius: 8px;
  position: relative;
}

.overlay-card .overlay-n img {
  opacity: 0.7;
  border-radius: 8px;
  height: 10.5rem !important;
  object-fit: cover !important;
}

.overlay-card .overlay-n span {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  font-size: 21px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* for property page */
.property-overlay-card .overlay-n {
  background-color: black;
  border-radius: 16px;
  position: relative;
}

.property-overlay-card .overlay-n .slider_virtual_tour {
  font-size: 11px;
  padding: 6px 10px;
  min-width: 120px;
}

.homes-sales h3 {
  color: #444;
}

.homes-sales .options a {
  color: #444444;
  margin-bottom: 1.3rem;
  text-decoration: none;
  text-transform: capitalize;
  font-size: large;
  width: auto;
  cursor: pointer;
  transition: .3s;
}
.homes-sales .options a:hover {
  color: #f5c954;
}
.custom-select-wrapper {
  position: relative;
  width: 9rem;
}

.custom-select {
  background-color: transparent;
  border: 1px solid #ccc;
  /* Customize border as needed */
  padding: 8px 12px;
  appearance: none;
  /* Remove default arrow */
  font-size: 1rem;
}

.custom-select::-ms-expand {
  display: none;
  /* Hide default arrow in IE */
}

.custom-select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  /* Customize size */
  color: #f5c954;
  /* Customize color */
  position: absolute;
  right: 10px;
  /* Positioning of the arrow */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the arrow does not block the dropdown */
}

.custom-select:focus {
  border-color: #666;
  /* Customize focus border color */
}

.point-two-imgs img {
  height: 500px;
  object-fit: cover;
}

.city_icon {
  position: absolute;
  background-color: #f5c954;
  color: #122844;
  font-size: 25px;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: -2%;
  display: grid;
  place-items: end end;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

@media screen and (max-width: 600px) {
  #Locality-new {
    .card .row span {
      font-size: 12px;
    }

    .know-about-city {
      color: #122844 !important;
    }

    .col-md-70 {
      width: 100%;
      flex: 0 0 auto;
    }

    .col-md-30 {
      width: 100%;
      flex: 0 0 auto;
    }
  }

  .buttons-grroup button {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    width: 100%;
  }

  .point-gallery .img-1 img {
    height: 30rem;
    object-fit: cover;
  }

  .locality_map_detail,
  .locality_map_detail2 {
    position: static;
    margin-top: 40px;
    box-shadow: 0px 0px 5px #808080c3;

  }

  .bd-main-section-map {
    height: 400px;
  }
}