.bd-agent-img {
    padding: 10px;
    border: 1px solid #F5C954;
    height: 340px;
    width: 80%;
}

.bd-agent-img>img {
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.agent-social-icons {
    width: 80%;
}

.bd-Agent-header {
    font-size: 25px;
    color: #122844;
    font-weight: 550;
}

.bd-details {
    color: #444444;
    font-size: 20px;
}

.bd-social-detail {
    color: #444444 !important;
    margin-bottom: 5px;
}

.bd-main-area-info {
    color: #122844;
    font-weight: 550;
}

.bd-detail-btn {
    background-color: #122844;
    border: none;
    border-radius: 6px;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    overflow: hidden;
    position: relative;
    padding: 10px 30px;
}

.bd-about-header {
    font-size: 24px;
    color: #122844;
    font-weight: 700;
}

.bd-info-agent {
    font-size: 15.5px;
    line-height: 25px;
    color: #444444;
}

.bd-info-agent p,
.bd-info-agent strong,
.bd-info-agent em,
.bd-info-agent span {
    color: #444444;
}

.bd-socialmedia {
    width: 25%;
    text-align: center;
}

.bd-social-links {
    height: 45px;
    width: 45px;
    display: grid;
    place-items: center;
}

.bd-links {
    font-size: 24px;
    color: white;

}

.fa-face {
    background-color: #3B5998;
    border-radius: 6px;
}

.fa-twit {
    background-color: #10ACEE;
    border-radius: 6px;
}

.fa-insta {
    background-color: #9531B7;
    border-radius: 6px;
}

.fa-linke {
    background-color: #0E76A8;
    border-radius: 6px;
}

.bd-awards-info {
    font-size: 15px;
    word-spacing: 0px;
    letter-spacing: 0px;
    color: #444444;
    font-weight: 500 !important;
    padding-left: 10px;
    border-left: 2px solid #F5C954;
}

.bd-Social-info {
    font-size: 15.5px;
    color: #444444;
    font-weight: 500;
    margin-bottom: 5px;
}

.bd-cities {
    padding: 5px 10px;
    background-color: #E8E9ED;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 600;
    color: #444444;
    text-decoration: none;
}

.bd-cities:hover {
    color: #444444;
}

.bd-vertical-sect {
    border-left: 3px solid #B5B5B5;
    height: 25px;
    width: 0px;
    margin-left: 50%;
    margin-top: 8px;
}

.team-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    box-shadow: 2px 2px 10px #b3b0b0;
    display: inline !important;
}

.bd-team-section {
    padding: 25px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.main-heading-agent {
    color: #122844;
    padding: 25px 0px;
    background-color: #F8F8F8
}

.bd-agent-head {
    font-weight: 600;
    font-size: 35px;
}

/* Switch Button */

.btn-container {
    text-align: center;
}

label {
    font-size: 16px;
    color: #122844;
    font-weight: 600;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 290px;
    height: 50px;
    background: #122844;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    top: 12px;
    right: 45px;
    color: #fff
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
    background: #122844;
    color: #122844;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 150px;
    height: 29px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    left: 4px;
    top: 3px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 10px 0px 5px 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 45px;
}

.btn-color-mode-switch input[type="checkbox"] {
    display: none
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 136px;
    background: #fff;
}

.bd-main-card-last-listing {
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    overflow: hidden;
    flex-direction: row;
}

.last-img {
    width: 45%;
    padding-right: 10px;
}

.bd-past-img {
    height: 100%;
    object-fit: cover;
}

.bd-second-past-section {
    width: 55%
}

.bd-decription-past-section {
    padding: 15px 10px 5px
}

.bd-main-rent-last-content {
    font-size: 19px;
    color: #122844;
    font-weight: bold;
    margin-bottom: 5px;
}

.bd-main-last-decription {
    font-size: 14px;
    color: #505050;
    margin-bottom: 8px;
}

.bd-three-past-rent {
    font-size: 13px;
    padding-top: 15px;
    border-top: 1px solid rgb(226, 218, 218);
}

/* Costing CSS */

.costing-section {
    width: 70%;
}

.bd-costing-section1 {
    flex: 1;
    padding: 13px;
    border: 2px solid #F2BE1A;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.bd-costing-section2 {
    padding: 0px 20px;
    background-color: #F5C954;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.bd-main-cost {
    color: #122844;
    font-size: 22px;
    font-weight: bold;
}

.bd-cost-text {
    color: #122844;
    font-size: 18px;
    font-weight: bold
}

.bd-section-costing1 {
    width: 80%;
}

.bd-section-costing2 {
    width: 20%;
    text-align: right;
}

.bd-collapse-section {
    border: 1px solid #123658;
    border-radius: 8px;
}

.collapse-costing {
    padding: 10px;
}

.bd-cost-text-collapse {
    color: #122844;
    font-size: 16px;
    font-weight: bold
}

.bd-main-cost-collapse {
    color: #122844;
    font-size: 18px;
    font-weight: bold;
}

.bd-bsp-section {
    width: 100%;
}

.bd-title-tbl {
    width: 40%;

}

.bd-title-tbl2 {
    width: 20%;
    text-align: center;
}

td,
th {
    border: 1px solid #143D63;
    border-bottom: transparent;
    border-left: transparent;
    border-right: transparent;
    padding: 5px 0px;
}

.bd-tr-tbl-section {
    border-right: 1px solid #143D63;
}

th {
    background-color: #E8E8E8;
    color: #505050;
    opacity: 0.8;
}

tr td {
    font-size: 14px;
    color: #505050;
    font-weight: 600;
}

tr:nth-child(odd) {
    background-color: #F8F8F8;
    border: none;
}

.review-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 50% 83%, 0% 100%);
    width: 100%;
    background-color: #80808013;
    height: 100%;
    filter: drop-shadow(2px 2px 10px rgba(50, 50, 0, 0.5));
    border-radius: 6px;

}

.review-container {
    padding: 40px 32px;
    text-align: center;
    border-radius: 3px;
    position: relative;
    min-height: 530px;
    /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5)); */
    z-index: 0;
}

.slick-active>button {
    width: 34px !important;
    height: 6px !important;
    background-color: #e5bd55 !important;
    border-radius: 5px;
    text-align: center;
    /* margin-right: 10px !important; */
    padding: 0% !important;
    transform: translateX(-8px) translateY(-1.5px);
}

.slick-dots li.slick-active button::before {
    display: none;
}

.slick-dots li button::before {
    opacity: .50;
    font-size: 7px !important;
}

.slick-dots li {
    margin: 0 8px;
}

@media screen and (max-width: 600px) {
    .slick-dots li {
        margin: 0 0px ;
    }
    .slick-active>button {
        width: 13px !important;
        transform: translateX(3px) translateY(-1.5px);
    }
}

.review-container>div>div>p {
    font-size: 15px;
    text-align: left;
    line-height: 25px;
    padding: 0 12px;
    color: #293c56;
}

.review-container>div .quote-top-icon {
    position: absolute;
    font-size: 100px;
    color: #F5C954;
    top: 24px;
    left: 0;
    line-height: 20px;
    z-index: 100;
}

.review-image {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #F5C954;
    position: absolute;
    bottom: 0%;
    left: calc(50% - 65px);
    z-index: 10;
}

.review-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    transform: translateY(-4.5px) translateX(-4.5px);
    object-position: top;
}

@media screen and (max-width:768px) {
    .review-container {
        padding: 22px;
    }

    .review-image {
        width: 130px;
        height: 130px;
        left: calc(50% - 65px);
    }

    .bd-costing-section1 {
        padding: 9px;
    }

    .bd-costing-section2 {
        padding: 0 10px;
    }

    .bd-cost-text {
        font-size: 15px;
    }

    .bd-main-cost {
        font-size: 17px;
    }
}