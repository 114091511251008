.counter_btn {
  border: none;
  background-color: #F2BE1A;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: 700;
  font-size: 18px;
}

.bd-decremental-buy-btn {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.bd-incremental-buy-btn {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.bd-input-incremental {
  height: 30px;
  max-width: 65px;
  border: none;
  border-top: 2px solid #F2BE1A;
  border-bottom: 2px solid #F2BE1A;
  padding: 3px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  outline: none;
}

.bd-home-icons {
  font-size: 20px;
  color: #122844;
  opacity: .8;
}

.bd-filter-section {
  padding: 30px;
}

.bd-filter-first-section1 {
  padding: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
}

.bd-first-sizing-filter {
  width: 20%
}

.bd-filter-type {
  padding-top: 5px;
}

.bd-filter-icons {
  height: 50px;
}

.bd-type-property-filter {
  color: #122844;
  font-size: 13px;
  margin-top: 5px;
  font-weight: 600;
}

.bd-type-property-section {
  padding: 12px 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
}

.amenity-card img{
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.active_amenity{
  background-color: #F2BE1A;
}
.active_amenity .bd-amenities-icons{
  color: #fff;
}


.bd-filter-first-section1-last {
  width: 43%;
  padding: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
}

.bd-facts-section1 {
  display: flex;
  padding: 6px;
  border: 1px solid #F2BE1A;
  border-radius: 5px;
}

.bd-sec-text {
  width: 100%;
  color: #122844;
  font-size: 14px;
  font-weight: 600;
}

.bd-first-status-section {
  width: 50%
}

.bd-custom-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container12 {
  display: block;
  position: relative;
  padding-left: 30px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: right
}

/* Hide the browser's default checkbox */
.container12 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1px;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 20%;
  border: 1px solid #F2BE1A
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container12 input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container12 .checkmark:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 10px;
  border-radius: 20%;
  border: solid #122844;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bd-days-dropdown {
  width: 100%;
  padding: 6px 20px !important;
  background-color: #E8E8E8 !important;
  border-radius: 8px;
  border: none !important;
  color: #122844 !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  font-size: 14px;
  box-shadow: none !important;
}

.bd-switch-text {
  font-size: 13px;
  padding: 10px 40px;
}


/* Custom switch btn */
.toggleCheckbox {
  display: none;
}

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 3px solid #122844;
  border-radius: 10px;
  background: #122844;
  color: #122844;
  cursor: pointer;
  font-size: 14px;
}

.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 10px;
  background: white;
  transition: all 0.3s;
}

.toggleContainer div {
  padding: 5px 8px;
  text-align: center;
  z-index: 1;
  letter-spacing: .5px;
}

.toggleCheckbox:checked+.toggleContainer::before {
  left: 50%;
}

.toggleCheckbox:checked+.toggleContainer div:first-child {
  color: white;
  transition: color 0.3s;
}

.toggleCheckbox:checked+.toggleContainer div:last-child {
  color: #122844;
  transition: color 0.3s;
}

.toggleCheckbox+.toggleContainer div:first-child {
  color: #122844;
  transition: color 0.3s;
}

.toggleCheckbox+.toggleContainer div:last-child {
  color: white;
  transition: color 0.3s;
}

/* ========================= */

.btn-color-mode-switch-filter>label.btn-color-mode-switch-inner-filter {
  margin: 0px;
  width: 210px;
  height: 35px;
  background: #122844;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
}

.btn-color-mode-switch-filter>label.btn-color-mode-switch-inner-filter:before {
  content: attr(data-on);
  position: absolute;
  font-size: 13px;
  font-weight: 600;
  top: 8px;
  right: 23px;
  color: #fff;
  width: 50%;
}

.btn-color-mode-switch-filter input[type="checkbox"]:checked+label.btn-color-mode-switch-inner-filter {
  background: #122844;
  color: #122844;
}

.btn-color-mode-switch-filter>label.btn-color-mode-switch-inner-filter:after {
  content: attr(data-off);
  width: 100px;
  height: 50%;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  /* padding: 6px 6px 6px 0; */
  width: 50%;

}

.btn-color-mode-switch-filter input[type="checkbox"]:checked+label.btn-color-mode-switch-inner-filter:before {
  content: attr(data-off);
  right: auto;
  left: 10px;
}

.btn-color-mode-switch-filter input[type="checkbox"] {
  display: none
}

.btn-color-mode-switch-filter input[type="checkbox"]:checked+label.btn-color-mode-switch-inner-filter:after {
  content: attr(data-on);
  left: 108px;
  background: #fff;
}

.bd-amenities-icons {
  font-size: 30px;
  color: #F2BE1A
}

.bd-btn-filter-more {
  background: #545D70 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  padding: 8px;
  font-size: 13px;
  width: 130px;
}

.bd-listing-type-dropdown {
  width: 50%;
}

.bd-content-box1 {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F0F0F0;
  position: absolute;
  width: 100% !important;
}

.bd-box-filter-section {
  padding: 0px 15px 15px 15px;
}


.bd-filter-reset {
  background: white 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  color: #122844;
  font-weight: 600;
  padding: 8px;
  font-size: 13px;
  width: 110px;
}

.bd-filter-close {
  background: white 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  color: #122844;
  font-weight: 600;
  padding: 8px;
  font-size: 13px;
  width: 130px;
}

.bd-filter-homes {
  background: #F5C954 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  padding: 8px;
  font-size: 13px;
  width: 130px;
}

.bd-main-btns-filter {
  padding: 10px;
  background-color: #122844;
}

.bd-main-last-btns {
  padding-top: 20px;
}

.dropdown-content2.New{
  position: relative;
}

.bd-content-box1.New{
  position: relative;
}

.dropdown-content2 {
  position: absolute;
  background-color: white;
  min-width: 200px;
  z-index: 1;
  background: #FFF;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  left: 0;
}

.dropdown-content2:before {
  content: "";
  position: relative;
  top: -16px;
  left: 25px;
  z-index: 1;
  border: solid 0px transparent;
  border-bottom-color: #FFF;
}