.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.top-city-box {
  height: 300px;
}

.city-image-box img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.city_name {
  align-items: end;
  background-color: #00000028;
  bottom: 0;
  color: #F5C954;
  display: flex;
  height: 100%;
  left: -1%;
  opacity: 1;
  padding: 20px 20px 0;
  padding-bottom: 16px;
  position: absolute;
  text-shadow: 1px 1px 2px #122844;
  transition: all .4s;
  width: 100%;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 15.5px;
  text-wrap: wrap;
}

.city-image-box:hover .city_name {
  opacity: 1;
  padding-bottom: 21px;
}

.top-city-box .city_name {
  font-size: 19px;
  padding: 24px 24px 20px;
  text-transform: uppercase;
}