.loan-value-box {
    padding: 6px 15px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    width: 200px;
}

.loan-value-box>div:first-child {
    width: 30%;
    margin-right: 10px;
    font-size: large;
    color: #444444;
}

.loan-value-box div>input {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: large;
    color: #444444;
    font-weight: 600;
    text-align: center;
}

.calculate-btn {
    padding: 12px;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
    background-color: #122844;
    display: block;
    width: 72%;
    margin: 20px auto;
    border: none;
    border-radius: 6px;
}

.loan-result-box {
    background-color: #F8F8F8;
    padding: 30px 0px;
}

.loan-result-box>div {
    width: 72%;
    min-height: 200px;
    margin: auto;
}

.output-input-field {
    width: 100%;
    padding: 10px;
    font-size: 17px;
    border: none;
    border-bottom: 2px solid #777 ;
    outline: none;
    color: #444444;
    font-weight: 600;
    background-color: transparent !important;
}