.rent-row {
  display: flex;
}

.bd-rent-section1 {
  width: 50%;
  padding-top: 91px;
}

.vertical-line {
  border-left: 1.5px solid #f2be1a;
  height: 500px;
}

.bd-section-Price {
  display: flex;
  padding: 15px 30px 10px;
  border-bottom: 1px solid #ebebeb;
}

.bd-section-recommended {
  padding: 15px 30px;
  border-bottom: 1px solid #ebebeb;
}

.bd-section1-row {
  display: flex;
  padding: 15px 30px 10px;
  border-bottom: 1px solid #ebebeb;
}

.bd-section-explore1 {
  width: 50%;
}

.bd-explore-rent {
  font-size: 22px;
}

.bd-btn-more {
  background: #545d70 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 8px;
  font-size: 11px;
  width: 130px;
}

.bd-price-rent {
  font-size: 18px;
  color: #113c64;
  font-weight: 600;
}

/* 
.custom-designed-select::after{
  content: '';
  background-color: #f2be1a;
  width: 12px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  z-index: 100;
} */
.custom-designed-select {
  background-color: #fff;
  border: 2px solid #f2be1a;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100px; */
  font-size: 13px;
  color: #2f425b;
  font-weight: 700;
}

.custom-designed-select button {
  padding: 3px 8px;
  background-color: #f2be1a;
  border: none;
  color: #fff;
  font-size: 15px;
}

/* sort-select-items */
.sort-select-field {
  width: 220px;
}

.sort-select-items {
  position: absolute;
  width: 100%;
  top: 95%;
  border: 1px solid #f2be1a;
  height: 340px;
  background-color: #fff;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 2px 2px 10px 10px;
  z-index: 1000;
}

.sort-select-items>div {
  cursor: pointer;
}

.sort-select-items>div:hover {
  background-color: #fdf4dd;
}

.bd-dropdown-price {
  height: 35px;
  /* padding: 5px 20px 5px 10px; */
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  border-color: #f2be1a;
  border-style: solid;
  border-radius: 5px;
  letter-spacing: 1px;
  background-image: linear-gradient(to right, white, white 65%, #f2be1a 35%);
}

.bd-dropdown-price-recommended {
  width: 210px;
  height: 32px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border-color: #f2be1a;
  border-style: solid;
  border-radius: 5px;
  letter-spacing: 1px;
  background-image: linear-gradient(to right, white, white 90%, #f2be1a 10%);
}

.view-tab-label {
  font-size: 15px;
  cursor: pointer;
  color: #0b1a2e;
  opacity: .6;
  font-weight: 500;
}

.view-tab-label.active {
  font-weight: 600;
  border-bottom: 2px solid #f5c954;
  opacity: 1;
}

.bd-sections-text {
  width: 22%;
}

.bd-sections-dropdown-min {
  width: 20%;
}

.bd-sections-dropdown-filter {
  width: 22%;
}

.bd-sections-dropdown-max {
  width: 24%;
}

.bd-content-part {
  width: 22%;
  font-size: 15px;
  color: #505050;
  letter-spacing: -0.5px;
  padding-top: 5px;
}

.col-md-dropdown {
  width: 50%;
}

.bd-dropdown-price-filter {
  width: 110px;
  height: 35px;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  border-color: #f2be1a;
  border-style: solid;
  border-radius: 5px;
  letter-spacing: 1px;
  background-image: linear-gradient(to right, white, white 75%, #f2be1a 25%);
}

.bd-type-view {
  font-size: 15px;
  color: #122844;
  text-decoration: none;
  font-weight: bold;
}

.bd-type-listing {
  font-size: 15px;
  color: #122844;
  text-decoration: none;
}

.bd-type-listing:hover {
  color: #122844;
}

.bd-type-view:hover {
  color: #122844;
}

.bd-decription-section {
  padding: 10px 15px 0px;
}

.card-img-top {
  height: 190px;
  object-fit: cover;
}

.bd-main-card-size {
  border-radius: 24px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  overflow: hidden;
}

.verticle-prop-card {
  min-height: 415px;
}

.bd-main-card-size>div>a {
  display: block;
  min-height: 180px;
  color: #0b1a2e;
  border-radius: 20px !important;
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.bd-main-card-size>div>a::after {
  content: '';
  background-color: #00000026;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

/* property_card_header */
.property_card_header {
  position: absolute;
  width: 100%;
  padding: 0px 12px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.property_card_footer{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.yellow-rotate-badge {
  background-color: #F5C954;
  height: 100px;
  left: -47px;
  position: absolute;
  top: -48px;
  width: 140px;
  transform: rotate(-40deg);
  z-index: 100;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-right: 10px;
  padding-bottom: 8px;
}

.yellow-rotate-badge span {
  font-size: 13px;
  text-align: center;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 2px #00000071;
}


.bd-main-card-size>div>a .favourite-icon {
  
  color: #fff;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  font-size: 22px;
  z-index: 10;
}

.bd-main-card-size>div>a .camera-icon {
  bottom: auto;
  top: 0px;
  text-align: center;
  color: #fff;
  font-size: 22px;
}

.camera-icon div div {
  font-size: 11px;
  transform: translateY(-5px);
}

.property_image {
  width: 45% !important;
}

.property_image+.card-body {
  width: 65%;
}

.bd-main-rent-content {
  font-size: 20px;
  color: #122844;
  font-weight: bold;
  margin-bottom: 5px;
}

.bd-main-decription {
  font-size: 13.5px;
  font-weight: 600;
  color: #505050;
}

.bd-two-home-section {
  width: 50%;
}

.bd-main-card-size2 {
  margin-right: 30px;
  margin-left: 15px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  overflow: hidden;
}

.property-badge-btn {
  position: absolute;
  z-index: 10;
  top: 16px;
  left: 16px;
  background-color: #F5C954;
  color: #fff;
  padding: 7px 13px;
  border-radius: 8px;
  font-size: 13px;
}

.horizontal-card-img {
  width: 45%;
}

.horizontal-card-img>a {
  height: 100%;
}

@media (max-width: 500px) {
  .horizontal-card-img {
    width: 100%;
  }
}

.bd-icon-section-rent {
  display: flex;
}

.middle-line {
  color: #f2be1a;
}

.bd-three-rent {
  width: 33.33%;
  font-size: 13.5px;
  color: #122844;
  border-right: 1px solid #f5c954;
  text-align: center;
}

.bd-three-rent:last-child {
  border-right: none;
}

.bd-three-rent svg {
  font-size: 21px;
  margin-bottom: 2px;
}

.bd-number {
  width: 5%;
  color: #122844;
  opacity: 40%;
  font-weight: bold;
}

.bd-numbers {
  width: 5%;
  font-weight: bold;
}

.bd-number-dashed {
  width: 35px;
  height: 2px;
  background-color: #111;
}

.pagination-row {
  display: flex;
  justify-content: center;
}

.cites-row {
  display: flex;
  justify-content: center;
  padding: 18px;
}

.cityname-section {
  color: white;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 10px solid #f2be1a;
  border-bottom: 5px solid transparent;
  margin-top: 8px;
}

.main-name {
  color: #f2be1a;
}

.cities-row {
  display: flex;
  margin-left: 60px;
}

.main-section-rent {
  background-color: #122844;
  width: 95%;
}

.bd-homes-header {
  color: #13243b;
  font-weight: bold;
  /* margin-left: 15px; */
}

.bd-table-section {
  width: 31%;
}

.bd-table-section-first {
  width: 38%;
}

.bd-neighbor-section {
  font-size: 24px;
  color: #444444;
  font-weight: 600;
}

.bd-main-home-content a {
  color: #444444;
  margin-bottom: 1rem;
  text-decoration: none;
  text-transform: capitalize;
  font-size: large;
  width: auto;
  cursor: pointer;
  transition: .3s;
}
.bd-main-home-content a:hover {
  color: #f5c954;
}

/* Detail Page Style */
.slider_img_container {
  height: 490px;
  background-color: #1228444b;
  border-radius: 12px;
  overflow: hidden;
}

.slider-img {
  height: 100%;
  object-fit: cover;
}

.slider-btns2 button {
  padding: 20px 9px;
  font-size: 22px;
  position: absolute;
  top: 41%;
  border: none;
  height: 18%;
  background-color: #4a4a4b;
  color: #f2c753;
}

.slider-btns2 button:first-child {
  border-radius: 0 20px 20px 0;
  left: 0;
}

.slider-btns2 button:last-child {
  border-radius: 20px 0px 0px 20px;
  right: 0;
}

.slider_property_type {
  position: absolute;
  top: 5%;
  right: 3%;
  min-width: 180px;
  padding: 10px 30px;
  border-radius: 9px;
  background-color: #fff;
  text-transform: uppercase;
  text-align: center;
  color: #122844;
  opacity: .96;
}

.slider_virtual_tour {
  padding: 8px 0px;
  right: auto;
  left: 3%;
  background-color: #143D63;
  opacity: .85;
  color: #fff;
}

.slider_share_like_btn {
  position: absolute;
  right: 3%;
  bottom: 5%;
}

.slider_share_like_btn button {
  background-color: #122844;
  opacity: .8;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 4px 9px 3px;
}

.bd-navbar-fixed {
  position: fixed;
  z-index: 1001 !important;
  background-color: white;
  width: 100%;
}

.bd-main-head-text {
  margin-top: 10px;
  font-size: 15.5px;
  color: #122844;
}

.bd-main-head-text span {
  cursor: pointer;
}

.Detail-row {
  border-bottom: 1px solid #e9e9e9;
  width: 1260px;
}

.header-row {
  display: flex;
}

.bd-header-content.active {
  font-size: 17px;
  color: #122844;
  font-weight: bold;
}

.bd-header-content button {
  font-size: 17px;
  color: #505050;
  font-weight: 500;
  border: none;
  background-color: transparent;
  padding: 5px 22px;
}

.bd-header-content button.active_tab_btn {
  border-bottom: 3px solid #F5C954;
  color: #113c64;
  font-weight: 700;
}

.bottom-division {
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 15px;
}

.bd-detail-tittle {
  width: 43%;
  font-size: 15.5px;
  color: #13243B;
  font-weight: 600;
}

.bd-detail-type {
  width: 62%;
  font-size: 15.5px;
  font-weight: 400;
  color: #464646;
}

.bd-detail-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f0f0f0;
}

.bd-tour-section {
  min-height: 110px;
  border-radius: 12px;
  display: grid;
  place-items: center;
  background-size: cover;
  background-position: bottom;
}

.bd-rent-detail {
  color: #3c3c3c;
  font-size: 40px;
  font-weight: bold;
}

.bg-custom-light {
  background-color: #f8f8f8;
  border-radius: 12px;
}

.bd-description-box1 {
  width: 55%;
  padding: 15px 15px 15px 35px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.bd-description-box2 {
  display: flex;
  width: 45%;
  padding: 25px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.bd-main-details {
  width: 33.33%;
  border-right: 2px solid #F5C954;
}

.bd-main-details:last-child {
  border-right: none;
}

.bd-main-details>div {
  font-size: 13px;
}

.main-area {
  color: #122844;
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 5px;
}

.main-detail-area {
  color: #444444;
  opacity: 0.8;
  font-size: 15.2px;
}

.bd-contact-form {
  padding: 20px;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  line-height: 1.8;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-modal-root .ant-modal-mask {
  z-index: 1050 !important;
}

.bd-icons-detail {
  font-size: 20px;
  padding-left: 13px;
  color: #122844;
}

.bd-header-sction {
  border-bottom: 1px solid #e9e9e9;
}

.AmenitiesBox {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: ; */
  gap: 3%;
}

.AmenitiesCard {
  flex-basis: 22%;
}

.AmenitiesCard img {
  width: 21px;
  height: 21px;
  object-fit: contain;
}

.bd-contact-header {
  font-size: 24px;
  color: #122844;
  font-weight: 550;
}

.bd-label-form {
  color: #13243B;
  font-weight: 600;
  font-size: 15.5px;
}

.bd-contact-input {
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  color: #122844;
  font-size: 15.5px;
  font-weight: 500;
  /* height: 35px; */
  outline: none;
  padding: 8px 15px;
  width: 100%;
  margin-top: 5px;
}

.bd-submit-btn {
  background-color: #16a9ea;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 15.5px;
  font-weight: 600;
  /* height: 30px; */
  outline: none;
  overflow: hidden;
  position: relative;
}

.bd-overview-section {
  width: 25%;
}

.bd-header-overview {
  color: #13243b;
  font-size: 24px;
  letter-spacing: .4px;
  font-weight: 600;
  line-height: 45px;
  /* line-height: 38px; */
}

.bd-header-overview span {
  font-weight: 400 !important;
}

.bd-main-overview {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px;
}

.bd-details-overview {
  color: #122844;
  font-size: 16.5px;
  font-weight: 550;
}

.bd-info-property {
  font-size: 16px;
  line-height: 1.3;
}

.bd-amenities-section {
  width: 20%;
}

.bd-details-amenities {
  color: #444444;
  opacity: 0.8;
  font-size: 13px;
  font-weight: 550;
}

.bd-icons-detail-amenities {
  font-size: 19px;
}

.bd-incremental {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 15px;
}

.bd-loan-section1 {
  width: 20%;
}

.bd-loan-section2 {
  width: 70%;
}

.bd-loan-section3 {
  width: 10%;
}

.bd-main-card-size-detail {
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  overflow: hidden;
}

.bd-two-home-section-detail {
  width: 25%;
}

.card-img-top-detail {
  height: 165px;
}

.bd-decription-section-detail {
  padding: 0px 15px;
}

.bd-employee-section {
  padding: 15px;
  background: #353939 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.empl-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.bd-name-emp {
  color: #f1bd34;
  font-size: 18px;
}

.bd-info {
  color: white;
  font-size: 14px;
  text-decoration: none;
}

.listPropenure {
  width: 72px;
  height: 100px;
  object-fit: cover;
  margin-right: 14px;
  border-radius: 8px;
}

.bd-list-name {
  color: #122844;
  font-size: 19px;
  font-weight: 550;
}

.bd-type-name {
  font-size: 12px;
}

.bd-info-details {
  color: #464646 !important;
  opacity: 0.8;
}

.bd-list-propusers-details {
  padding: 25px 25px 5px;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.bd-lists {
  border-bottom: 1px solid #e9e9e9;
}

.bd-visiting-btn {
  background-color: #122844;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 85%;
}

.bd-visiting-section {
  padding: 25px;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.slick-prev::before,
.slick-next::before {
  color: black;
}

.image-icons-details {
  position: relative;
  width: 30px;
}

.bd-button-one {
  width: 50%;
}

.bd-visiting-btn-time {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1.5px solid #b2b2b2;
  border-radius: 8px;
  border-radius: 6px;
  box-shadow: none;
  color: #122844;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 45px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 85%;
}

.bd-underscore-overview {
  width: 93px;
  border-bottom: 3px solid #f5c954;
  white-space: nowrap;
}


/* new for property map */
.property_map_box {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
}

.property_map_box .leaflet-popup-content-wrapper {
  background-color: #122844c2;
  /* opacity: 0.8; */
  width: auto;
}

.property_map_box .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 400px !important;
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 15px 15px 0px;
}

.property_map_box .leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute !important;
  left: 16% !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  overflow: hidden;
  pointer-events: none;
  bottom: -19px !important;
  transform: rotate(0deg);
}

.property_map_box .leaflet-popup-tip-container .leaflet-popup-tip {
  background-color: #122844;
  opacity: 0.8;
}

.map-property-popup {
  width: 100% !important;
  padding: 12px;
  border-radius: 10px;
}

.map-property-popup-box {
  max-height: 440px;
  overflow: auto;
  scrollbar-width: thin;

}

.map-property-popup img {
  width: 130px;
  height: 100px;
  border-radius: 10px;
  background-color: #444444;
  object-fit: cover;
}

.map-property-popup .popup-content {
  flex: 1;
}

.map-property-popup p {
  font-size: 12px;
}

.map-property-popup .bd-three-rent>div {
  display: flex;
  justify-content: space-around;
  padding: 0 4px;
  align-items: center;
  font-size: 9px;
}

.map-property-popup .bd-three-rent svg {
  font-size: 13px;
}

.sell-all-properties-link:hover {
  color: #f5c954;
}

.property-location-box {
  position: absolute;
  background-color: #122844c2;
  bottom: 7%;
  left: 5%;
  width: 300px;
  min-height: 300px;
  z-index: 1000;
  padding: 17px 17px 2px;
  border-radius: 15px;
}

.property-location-white-box {
  background-color: white;
  padding: 12px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.property-location-white-box>div>div {
  width: 43px;
  height: 43px;
  background-color: #E8E9ED;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: auto;
  font-weight: 600;
  font-size: 17px;
}

.property-location-white-box:hover div>div {
  background-color: #F5C954;
}

.bd-buy-navbar {
  position: fixed;
  background-color: white;
  width: 50%;
  z-index: 999;
}

.with_neigh {
  padding-top: 210px;
}

.without_neigh {
  padding-top: 148px;
}

/* nearby-places map */
.nearby-places {
  height: 550px !important;
  width: 100%;
}

.nearby-places .leaflet-popup-tip-container {
  transform: rotate(0deg);
  bottom: -19px;
  left: 45% !important;
}

.nearby-places .leaflet-popup-content-wrapper {
  border-radius: 10px;
  outline: 2px solid #F5C954;
  color: #113C64 !important;
  font-weight: 600;
  text-align: center;
}

.nearby-places .leaflet-popup-content-wrapper>div>div {
  font-size: 15px;
}

.nearby-places .leaflet-popup-content-wrapper>div>span {
  font-size: 13px;
  color: #353939 !important;
  opacity: .8;
}

.map_view_control {
  position: absolute;
  z-index: 1000;
  top: 14px;
  left: 55px;
  width: 58px;
  height: 58px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  border: 4px solid #f2c753;
}

.loan-calculator-box table td{
  border: none !important;
}

/* Resposive Section */

@media (max-width: 800px) {
  .nearby-places {
    height: 900px !important;
  }

  .property-location-box {
    width: 90%;
  }

  .without_neigh {
    padding-top: 10px;
  }

  .with_neigh {
    padding-top: 10px;
  }

  .slider_img_container {
    height: 280px;
  }

  .header-row {
    width: 100%;
    overflow: auto;
  }

  .header-row button {
    min-width: 150px;
  }

  .bd-navbar-fixed {
    position: static;
    /* overflow: auto; */
  }

  .AmenitiesCard {
    flex-basis: 48.5%;
    font-size: 13px;
  }

  .loan-calculator-box td>div {
    width: 200px !important;
  }

  .loan-calculator-box td>h5 {
    font-size: 14px;
  }

  .loan-calculator-box table {
    width: 600px !important;
  }

  .slider_property_type {
    min-width: auto;
    font-size: 12px;
  }

  .slider_virtual_tour {
    padding: 8px 10px;
  }

  .slider-btns2 button {
    padding: 9px 6px;
  }

  .bd-header-overview {
    color: #13243b;
    font-size: 29px;
    margin-bottom: 0;
  }

  .bd-detail-type {
    font-size: 14px;
  }

  .Detail-row {
    width: 100%;
  }

  .bd-label-form {
    display: inline;
    font-size: 14px;
  }

  .card-img-top {
    height: 215px;
  }

  .rent-row {
    flex-wrap: wrap;
  }

  .bd-rent-section1 {
    width: 100%;
  }

  .bd-section-explore1 {
    padding-top: 10px;
    width: 100%;
  }

  .bd-buy-navbar {
    width: 100% !important;
    position: static !important;
  }

  .bd-fixed-navbar {
    padding-top: 30px;
  }

  .bd-section1-row {
    flex-wrap: wrap;
    padding: 15px 15px 15px;
  }

  .responsive-left {
    text-align: left;
  }

  .bd-section-Price {
    flex-wrap: wrap;
    padding: 15px 25px 15px;
  }

  .bd-sections-dropdown-filter {
    width: 36%;
    margin: auto;
  }

  .bd-sections-text {
    width: 33%;
  }

  .bd-sections-dropdown-min {
    width: 33%;
    margin-bottom: 10px;
  }

  .bd-section-recommended {
    flex-wrap: wrap;
    padding: 15px 25px 15px;
  }

  .bd-content-part {
    width: 30%;
  }

  .bd-homes-card {
    flex-wrap: wrap;
  }

  .bd-two-home-section {
    width: 100%;
  }

  .bd-main-card-size,
  .bd-main-card-size2 {
    margin: 0px 0px 20px;
  }

  .bd-number {
    width: 10%;
  }

  .main-section-rent {
    width: 100%;
  }

  .main-name,
  .triangle-right,
  .cityname-section {
    padding-right: 10px;
  }

  .cities-row {
    margin-left: 20px;
  }

  .bd-neighbor-section {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .bd-table-section-first {
    width: 40%;
  }

  .bd-table-section {
    width: 30%;
  }

  .property_map_box {
    position: static;
    padding-top: 30px;
  }
}