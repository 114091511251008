.bd-carrer-first-sec1 {
  width: 30%;
}

.bd-carrer-first-sec1 p {
  width: 60%;
}

.bd-carrer-first-sec2 {
  width: 25%;
}

.bd-carrer-first-sec2 img {
  width: 40%;
  margin-bottom: 105px;
}

.bd-carrer-first-sec3 {
  width: 45%;
}

.bd-dots-career {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f2be1a;
}

.bd-vertical-text {
  rotate: 270deg;
  width: 23%;
}

.hr-lines {
  width: 50px;
  height: 2px !important;
  color: #f2be1a;
  opacity: 1;
}

.career-third-sec1 {
  width: 35%;
}

.career-third-sec1 p {
  width: 68%;
  font-size: 18px;
}

.career-third-sec2 {
  width: 10%;
}

.career-third-sec3 {
  width: 10%;
}

.career-third-sec4 {
  width: 20%;
}

.bd-side-img {
  width: 56%;
}

.bd-small-text {
  width: 40%;
  margin: auto;
  font-size: 18px;
}

.bd-head-caeer {
  width: 88%;
}

.bd-empty-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #f2be1a;
}

.bd-fa-check-cir {
  padding: 40px;
  border: 2px solid #20a5e0;
  border-radius: 50%;
}

.bd-circle {
  font-size: 30px;
  color: #20a5e0;
}

.prop-first {
  width: 20%;
}

.prop-second {
  width: 60%;
}

.bd-global {
  width: 60%;
}

.bd-join-career {
  padding: 8px 40px 8px 8px;
  background-color: #f5c954;
  color: white;
  text-decoration: none;
  font-size: 22px;
  border-radius: 2px;
  width: 200px;
}

.bd-join-career span {
  font-size: 24px;
  font-weight: 600;
}

.bd-location-union {
  width: 90%;
}

.bd-union-img {
  width: 72%;
  position: relative;
  z-index: 99;
}

/* .bd-india-map {
  border-radius: 50%;
  width: 46%;
  height: 470px;
  position: absolute;
  top: 6.5%;
  left: 3%;
  z-index: 999;
  clip-path: circle(50% at 50% 50%);
} */

.bd-india-map{
  border-radius: 10px;
  width: 95%;
  margin: auto;
  height: 550px;
}

.bd-india-logo{
  position: relative;
}

.loctaion-right{
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 999;
}

.bd-union-relative {
  position: relative;
}

.bd-world-map {
  width: 90%;
  position: absolute;
  top: 25%;
  right: 12px;
}

.bd-icons-office {
  padding: 10px;
  border-radius: 50%;
  background-color: white;
}

.bd-office-buttons {
  padding: 10px 0px 10px 20px;
  width: 200px;
  /* height: 43px; */
  background-color: #feb029;
  border-radius: 9px;
  cursor: pointer;
}

.office-location-card {
  width: 85%;
  margin-right: auto;
}

.bg-office {
  background-color: #fe456b;
}

.bg-location {
  background-color: #0faffb;
}

/* .loctaion-right {
  margin-left: -50px;
} */

.bd-dotted-map {
  position: absolute;
  top: 48%;
  left: 69%;
  width: 9%;
}

.bd-loctaion-section {
  width: 30%;
}

.bd-all-location h5 {
  font-size: 20px;
  color: #122844;
  font-weight: 600;
}

.bd-all-location p {
  font-size: 15px;
  color: #464646;
  opacity: 0.8;
}

.bd-info-loca {
  font-size: 15px;
  color: #464646 !important;
  opacity: 0.8;
}

.loc-second-sect {
  width: 28%;
}

.bd-pointer-loca {
  position: absolute;
  top: 25%;
  left: 21.5%;
  z-index: 999;
  width: 26px;
}

.bd-pointer-detail {
  width: 23%;
  padding: 13px 14px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  top: 13%;
  z-index: 999;
  right: 51.8%;
}

.color-blue {
  color: #122844;
}

.bd-pointer-detail:after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 60px;
  border-top: 15px solid transparent;
  border-right: 15px solid white;
  border-left: none;
  border-bottom: 15px solid transparent;
}

.bd-pointer-detail h5 {
  color: #122844;
  font-weight: 600;
  font-size: 18px;
}

.bd-pointer-detail p {
  color: #464646;
  font-size: 14px;
  opacity: 0.8;
}

.bd-pointer img {
  width: 17px;
}

.bd-pointer1 {
  position: absolute;
  top: 0px;
  color: #0faffb;
  top: 43%;
  left: 15%;
  z-index: 999;
  font-size: 26px;
}

.bd-pointer2 {
  position: absolute;
  top: 0px;
  color: #0faffb;
  top: 55%;
  left: 17.5%;
  z-index: 999;
  font-size: 26px;
}

.bd-pointer3 {
  position: absolute;
  top: 0px;
  color: #0faffb;
  top: 67%;
  left: 21%;
  z-index: 999;
  font-size: 26px;
}

.bd-pointer4 {
  position: absolute;
  top: 0px;
  color: #0faffb;
  top: 43%;
  left: 37.3%;
  z-index: 999;
  font-size: 26px;
}

.editor_main_content .pt-30.mb-120 {
  margin-bottom: 0 !important;
}

.editor_main_content .pt-30.mb-190 {
  margin-bottom: 0 !important;
}

.backImg {
  width: 80% !important;
}

.color-blue {
  color: #0faffb;
}

/* circles */
.propuser-large-text {
  color: black;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #e5e6ea;
  font-size: 182px;
  font-weight: 900;
  position: absolute;
  bottom: -6%;
  left: 1%;
}

.big-circle {
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: 110px;
  right: 10px;
  z-index: 0;
}

.left-small-circle {
  position: absolute;
  width: 64px;
  height: 64px;
  bottom: 350px;
  left: 190px;
  z-index: 0;
}

.right-small-circle {
  position: absolute;
  width: 64px;
  height: 64px;
  z-index: 0;
}

.right-small-circle:nth-child(1) {
  right: 60px;
  z-index: 10;
  top: -15px;
  z-index: 0;
}

.right-small-circle:nth-child(2) {
  left: -100px;
  z-index: 10;
  bottom: 90px;
  z-index: 0;
}

/* ========== */

.bd-underscore-overview h4 {
  font-size: 26px;
}

.bd-terms-head span {
  font-size: 26px;
  color: #122844;
  font-weight: 600;
  line-height: 45px;
}

.bd-terms-head span:nth-of-type(1) {
  border-bottom: 3px solid #f2be1a;
}

.about-content {
  /* width: 62%;
  margin-left: 25.5%;
  padding-top: 12.5%; */
  position: relative;
  z-index: 1;
}

.about-content p {
  color: #555;
  font-size: 15.5px;
}

.about-content .about_desc p {
  font-family: "Poppins", sans-serif;
  font-size: 15.5px;

}

.about-circles {
  transform: translateY(330px);
}

.about-circles .big-circle {
  width: 260px;
  height: 260px;
  bottom: 110px;
  right: 0px;
}

.about-circles .left-small-circle {
  left: 10px;
}

.about-circles .right-small-circle:nth-child(1) {
  right: 40px;
  top: -15px;
}

.about-circles .right-small-circle:nth-child(2) {
  left: -340px;
  bottom: 100px;
}

.bd-policy-terms {
  padding-top: 100px;
}

.w-70 {
  width: 80%;
}

.about-first-para {
  color: #444444;
}

.pd-first-city {
  width: 30%;
  color: #122844;
  font-weight: 550;
  font-size: 17px;
}

.pd-first-city span {
  color: #f2be1a;
  font-weight: 550;
}

.pd-first-city-arrow {
  /* width: 3%; */
  border-radius: 50px;
  cursor: pointer;
}

.pd-first-city-arrow:active {
  color: #999;
}

/* About us */
.mission-rectangle-dots {
  position: absolute;
  width: 80px !important;
  top: 10%;
  right: 0%;
}

.mission-galary {
  position: relative;
}

.mission-galary .mission-image {
  border-radius: 16px;
  width: 100%;
  object-fit: cover;
}

.mission-galary .mission-image1 {
  height: 280px;
}

.mission-galary .mission-image2 {
  height: 120px;
  width: 65%;
  margin-top: 15px;
}

.mission-galary .mission-image3 {
  width: 65%;
  height: 150px;
}

.mission-galary .mission-image4 {
  margin-top: 15px;
  height: 220px;
}

.mission-galary>div:nth-child(2) {
  transform: translateY(12%);
}

.left-square-dots {
  width: 160px !important;
  position: absolute;
  bottom: -19%;
  left: 0;
  z-index: -1;
}

.right-square-dots {
  width: 160px !important;
  position: absolute;
  top: -4%;
  right: 13%;
  z-index: -1;
}

/* vision */
.vision-box {
  background-size: cover;
}

.vision-details {
  width: 80%;
  transform: translateX(-62%);
  background-color: #fff;
  padding: 40px 90px 40px 40px;
  /* height: 100%; */
}

.vision-top-shadow {
  box-shadow: 1px 0 40px rgba(128, 128, 128, 0.26);
}

@media screen and (max-width: 800px) {
  .vision-details {
    width: 90%;
    transform: translateX(5%);
    padding: 30px 40px 30px 30px;
  }

  .about-content {
    width: 100%;
    padding-left: 5%;
    padding-top: 7%;
    position: relative;
    z-index: 1;
  }

  .propuser-large-text {
    display: none;
  }

  .bd-responsive-text-terms {
    min-height: 80vh;
  }
}

/* top-propenuer | propenuer-polygon-image */
.top_proprenuer_card {
  border-radius: 20px;
  cursor: pointer;
}

.top_proprenuer_card img {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

.top_proprenuer_card>div>div,
.top_proprenuer_card>div>h5 {
  font-weight: 600;
}

.active_card_box {
  position: absolute;
  background-color: #11111191;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.active_card {
  border-radius: 15px;
  position: relative;
}

.active_card>img {
  height: 220px;
  border-radius: 15px 15px 0 0;
  object-fit: contain;
  object-position: top;
  background-color: #f3f3f3;
}

.prop_desc {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: #222 !important;
}

.close-card-btn {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 32px;
  color: #122844;
  cursor: pointer;
}

/* old */

.top-propenuer p {
  line-height: 22px;
}

.propenuer-polygon-image {
  width: 160px;
  height: 180px;
  object-fit: cover;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.first-propenuer-images {
  transform: translateY(12%) translateX(-11%);
  position: relative;
  z-index: 5;
}

.second-propenuer-images {
  transform: translateY(-13%) translateX(11%);
  position: relative;
  z-index: 5;
}

.first-propenuer-images>div {
  position: absolute;
  width: 110%;
  left: -5%;
  border: 2px dashed #f5c954;
  border-top: none;
  top: -10%;
  height: 60%;
}

.second-propenuer-images>div {
  position: absolute;
  width: 110%;
  left: -5%;
  border: 2px dashed #f5c954;
  border-bottom: none;
  bottom: -10%;
  height: 60%;
}

.first-propenuer-images>div>div,
.second-propenuer-images>div>div {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #f5c954;
}

.first-propenuer-images>div>div:first-child,
.second-propenuer-images>div>div:first-child {
  transform: translateX(-57.2%);
}

.first-propenuer-images>div>div:last-child,
.second-propenuer-images>div>div:last-child {
  transform: translateX(57.2%);
}

/* data-containers */
.first-second-data {
  transform: translateX(-10%);
}

.third-fourth-data {
  transform: translateX(10%) translateY(-17%);
}

/* polygon symbol  */
.first-propenuer-images>div::before,
.first-propenuer-images>div::after,
.second-propenuer-images>div::before,
.second-propenuer-images>div::after {
  content: "";
  width: 22px;
  height: 24px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: inline-block;
  background-color: #f5c954;
  position: absolute;
  z-index: 10;
}

.first-propenuer-images>div::before {
  left: 7%;
  bottom: -10%;
}

.first-propenuer-images>div::after {
  right: 7.3%;
  bottom: -10%;
}

.second-propenuer-images>div::before {
  left: 7%;
  top: -12%;
}

.second-propenuer-images>div::after {
  right: 7.3%;
  top: -12%;
}

.rera-slider {
  width: 92%;
}

@media screen and (min-width: 420px) and (max-width: 768px) {

  .mission-rectangle-dots,
  .left-square-dots {
    display: none;
  }

  .mission-galary .mission-image1 {
    height: 200px;
  }

  .mission-galary .mission-image2 {
    width: 80%;
  }

  .bd-location-union {
    width: 100%;
    padding-top: 14px;
  }

  .loctaion-right {
    right:5%;
    top: auto;
    bottom: 5%;
    margin-left: 0px;
    margin: auto;
  }

  .bd-pointer-detail {
    width: 44%;
    top: -4%;
    right: 13.8%;
  }

  .bd-pointer-detail h5 {
    font-size: 12px;
  }

  .bd-pointer-detail p {
    font-size: 11px;
  }

  .bd-locate-icon {
    font-size: 12px;
  }

  .bd-info-loca {
    font-size: 12px;
  }

  .bd-union-img {
    width: 100%;
  }

  .bd-india-map {
    width: 95%;
    height: 340px;
    margin: auto;
    position: static;
    /* clip-path: none; */
  }

  .bd-pointer-loca {
    top: 21%;
    left: 27.5%;
    width: 16px;
  }

  .bd-pointer1 {
    display: none;
  }

  .bd-pointer2 {
    display: none;
  }

  .bd-pointer3 {
    display: none;
  }

  .bd-pointer4 {
    display: none;
  }

  .bd-world-map {
    width: 100%;
  }

  .bd-header-overview {
    font-size: 29px !important;
  }

  .bd-second-office {
    padding-top: 60px;
  }

  .bd-two-locate {
    padding: 0px;
  }

  .bd-loctaion-section {
    width: 90%;
    padding-bottom: 10px;
  }

  .container-3 {
    padding-right: 0px !important;
    width: 95%;
  }

  div.w-50.bd-section-one-terms {
    width: 100% !important;
    padding: 0px;
  }

  .bd-responsive-text-terms {
    display: block;
    padding: 10px;
  }

  .bd-responsive-text-terms1,
  .bd-first-about-text {
    display: none;
  }

  .rera-slider {
    width: 85%;
  }

  .pd-first-city {
    font-size: 15px;
  }

  .about-secnd-1 {
    width: 100% !important;
  }

  .bd-our-mission {
    width: 100% !important;
    padding: 30px 0px 0px 0px;
  }

  .bd-vision-text {
    position: relative;
    padding: 15px;
    width: 90%;
    margin-top: 10px;
  }

  .bd-vision-img {
    width: 100%;
  }

  .response-reverse-columns {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .responsive-spacing {
    margin-top: 30px;
  }
}

@media (min-width: 1600px) {
  .top_proprenuer_card .top_card_img {
    height: 400px;
  }
}

@media (max-width : 400px) {

  .loctaion-right{
    position: static;
    width: 100%;
  }

  .bd-office-buttons{
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
  }
  
}