.multi-range-slider {
    box-shadow: none !important;
    border: none !important;
    padding: 5px 0px !important;
}

.multi-range-slider .bar {
    transform: rotate(0deg) !important;
    position: static;
    border-radius: 0px;
    height: 2px;
}

.multi-range-slider .bar-inner {
    background-color: #717e8f !important;
    box-shadow: none !important;
    border: none !important;
    /* height: 2px; */
}

.multi-range-slider .thumb {
    background-color: #f2be1a !important;
    border: none !important;
    box-shadow: none !important;
    position: relative !important;
    z-index: 100 !important;
}

.multi-range-slider .thumb::after {
    content: '' !important;
    position: absolute !important;
    background-color: #fff;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    top: -5px;
    left: -6px;
    display: inline-block;
    z-index: 100;
}

.multi-range-slider .thumb::before {
    width: 25px !important;
    height: 25px !important;
    border: none !important;
    box-shadow: none !important;
    top: -3px !important;
    z-index: 90 !important;
}

.multi-range-slider .bar-left {
    padding: 0px !important;
    height: 2px;
}

.multi-range-slider .thumb .caption * {
    background-color: #fff !important;
    color: #113c64 !important;
    font-weight: 600;
    font-size: 15px !important;
    box-shadow: none !important;
    border: 1px solid #f2be1a;
    line-height: 18px !important;
    padding: 5px 15px !important;
    border-radius: 8px !important;
    visibility: visible !important;
}

.multi-range-slider .caption {
    display: flex !important;
    bottom: auto;
    top: 50px;
}

.multi-range-slider .bar-right,
.multi-range-slider .bar-left {
    box-shadow: none !important;
    background-color: #717e8f !important;
}

.multi-range-slider .ruler {
    margin: 80px 0px -5px 0px !important;
    display: none !important;
}

.multi-range-slider .labels {
    display: none !important;
}

.price-filter-box {
    box-shadow: 0px 0px 5px #cdcbcb;
    background-color: #fff;
    padding: 20px 26px 54px;
    position: absolute;
    top: -13px;
    left: 4%;
    width: 92%;
    border-radius: 10px;
    display: none;
    z-index: 10;
}

.price-filter-box::after {
    content: '';
    display: inline-block;
    width: 40px;
    height: 20px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #fff;
    position: absolute;
    top: -8%;
    left: 30%;
}

.price-separator-bottom {
    position: absolute;
    top: 150%;
    font-weight: 600;
    font-size: 11px;
    z-index: 0 !important;
    color: #5e6977;
}

.price-separator-bottom::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 10px;
    background-color: #717e8f;
    position: absolute;
    top: -76%;
    left: 0%;
}

.price-separator-top {
    position: absolute;
    bottom: 150%;
    font-weight: 600;
    font-size: 11px;
    z-index: 0;
    color: #5e6977;
}

.price-separator-top::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 10px;
    background-color: #717e8f;
    position: absolute;
    bottom: -76%;
    left: 0;
}

.price-separator-bottom > div,
.price-separator-top > div {
    transform: translateX(-5px);
}

@media (max-width: 768px) {
    .price-filter-box{
        top: -60px !important;
    }
}