
#HomeLoan {
  .bd-contact-details {
    transform: translateX(-13%) translateY(26%);
  }

  .rounded-6 {
    border-radius: 1.2rem;
  }

  .total-investment-conatiner {
    /* width: 83%; */
    /* width: ;
    height: 500px; */
    /* padding: 50px 70px 50px 40px; */
    background-image: url("../../assets/footer/contact-detail.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .calculator-container-1 {
    height: 500px;
  }

  .calculator-container-2 {
    height: 500px;
  }

  .bg-curve-2 {
    background-image: url("../../assets/footer/contact-detail.png");
    background-repeat: no-repeat;
    width: 100%;
    z-index: 2;
    top: 0;
    right: 0;
  }

  .bd-detail-head-home h4 {
    color: #122844;
    font-weight: 600;
    font-size: 24px;
  }

  .total-loan-card {
    background-color: #fae4aa !important;
    border-radius: 0.75rem;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #122844;
  }

  .Total-Interest-Payment h6 {
    font-size: 0.8rem;
  }

  .Total-Interest-Payment p {
    font-size: 0.8rem;
  }

  .w-83 {
    width: 83%;
  }

  .w-90 {
    width: 90% !important;
  }

  .box-value {
    border: 2px solid #f5c955;
    /* padding: 0.3rem 0.5rem; */
    border-radius: 5px;
  }

  .bg-contact-detail-img {
    width: 100%;
    padding: 50px 38px 50px 38px !important;
  }

  .button-group .submit {
    cursor: pointer;
    color: #fff;
    background-color: #122844;
    border-color: #122844;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 8px;
  }

  .button-group .reset {
    cursor: pointer;
    color: #122844;
    /* background-color: #122844; */
    border-color: #122844;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 8px;
  }

  .button-group .reset:hover {
    color: #fff;
    background-color: #122844;
  }

  /* .table th:not(:last-child),
  .table td:not(:last-child) {
    border: none !important;
  } */

  .text-color {
    color: #122844;
    font-weight: 700;
  }

  .sort {
    cursor: pointer;
    color: #122844;
    /* background-color: #122844; */
    border: 2px solid #122844;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16.5px;
    width: 260px;
  }

  .sort:hover {
    color: #fff;
    background-color: #122844;
  }
}

#HomeLoan .table-section {

  .table th,
  .table td {
    border: none !important;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem !important;
  }

  .th {
    color: #122844;
    font-weight: 700;
  }

  tbody tr td div {
    color: #333;
  }

  tbody tr td div span {
    color: #122844;
  }
}

#HomeLoan .bank-section {

  .table th,
  .table td {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    /* text-align: center; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c1c1;
    background-color: white;
  }

  .th {
    color: #122844;
    font-weight: 600;
    border-bottom: none !important;
    font-size: 16.5px;
  }

  .btn-apply {
    cursor: pointer;
    color: #fff;
    background-color: #122844;
    border: 1.5px solid #122844;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 8px;
    width: 100%;
  }

  .img-bank {
    width: auto;
    height: 65px;
  }

  .center-content {
    /* margin-top: 4%; */
  }
}

@media (max-width: 850px) {
  #HomeLoan .bd-contact-details  {
    /* padding: 20px !important; */
  }

  #HomeLoan .bd-contact-details{
    transform: translateX(0) translateY(0);
  }

  #HomeLoan .bg-contact-detail-img{
    padding: 20px !important;
  }
}
