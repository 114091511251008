.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* Space between items */
}

.item {
    background-color: white;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: box-shadow 0.2s, transform 0.2s;
}

.item:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.itemTitle {
    font-size: 1em;
    color: #122844;
    margin: 0 0 10px;
    font-weight: 600;
}

.itemDescription {
    font-size: .8em;
    color: #666;
    margin: 0 0 15px;
}

.itemLink {
    color: #122844;
    text-decoration: none;
    font-weight: 600;
}

.itemLink:hover {
    color: #f5c954;
    text-decoration: underline;
}