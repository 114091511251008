.agent-form-container {
    background-color: #fff;
    width: 92%;
    margin: auto;
    /* height: 75%; */
    padding: 5em 11em 4em;
    /* position: relative; */
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.agent-heading {
    text-transform: capitalize;
    position: relative;
}

.agent-heading div:nth-child(2) {
    font-size: 28px;
    color: #333;
    font-weight: 600;
}

.agent-heading div:first-child {
    font-size: 63px;
    position: absolute;
    top: -45px;
    left: -2px;
    font-weight: 600;
    color: #99999937;
}

.agent-heading h3 {
    font-weight: bold;
    color: #122844;
    font-size: 35px;
}

.agent-input-field {
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    width: 100%;
    font-size: 20px;
    color: #444;
    padding: 10px 0px;
}

.agent-input-field:focus {
    border-bottom: 2px solid #f2be1a !important;
}

.agent-ok-btn{
    height: 56px;
}
/* Select Box */
.css-13cymwt-control {
    border: 0 !important;
    outline: none !important;
    font-size: 21px;
}

.css-1fdsijx-ValueContainer {
    padding-left: 0 !important;
}

.css-1dimb5e-singleValue{
}

/* ========== */

/* phone field */
.react-international-phone-input {
    border: 0 !important;
    padding-top: 10px;
    padding-bottom: 9px;
    font-size: 19px !important;
    margin: 0px 0;
    color: #666 !important;
}

.react-international-phone-country-selector-button {
    border: 0 !important;
}

/* ========== */

.step-btn {
    min-width: 25px;
    height: 25px;
    display: grid;
    place-items: center;
    font-weight: 600;
    border-radius: 50px;
}

.bar {
    transform: rotate(14deg);
    border-radius: 20px;
    position: absolute;
}

.bar1 {
    top: 0;
    left: 4%;
    width: 15px;
    height: 82px;
    background: #322805;
    opacity: .5;
}

.bar2 {
    top: 0;
    left: 5.8%;
    width: 20px;
    height: 162px;
    background: #3535356c;
    opacity: .4;
}

.bar3 {
    top: 0;
    left: 7.6%;
    width: 25px;
    height: 300px;
    background: #353535;
    opacity: .1;
}

.bar4 {
    bottom: 0;
    right: 4%;
    width: 15px;
    height: 82px;
    background: #322805;
    opacity: .5;
}

.bar5 {
    bottom: 0;
    right: 5.8%;
    width: 20px;
    height: 162px;
    background: #3535356c;
    opacity: .4;
}

.bar6 {
    bottom: 0;
    right: 7.6%;
    width: 25px;
    height: 300px;
    background: #353535;
    opacity: .1;
}

@media screen and (min-width: 1500px) {
    .bar {
        transform: rotate(17deg);
    }
}

@media screen and (max-width: 1200px) {
    .bar {
        transform: rotate(12deg);
    }
}

@media (max-width: 768px) {
    .agent-form-container {
        padding: 3em 2em;
        clip-path: none;
    }

    .agent-form-container {
        width: 100%;
    }

    .bar {
        display: none;
    }

    .step-btn {
        min-width: 20px;
        height: 20px;
        font-size: 10px;
    }

    .bg-image {
        padding: 0px 15px;
        min-height: 90vh;
    }

    .agent-heading div:nth-child(2) {
        font-size: 20px;
    }

    .agent-heading h3 {
        font-size: 24px;
    }

    .css-uuc63l-control {
        font-size: 16px !important;
    }

    .agent-input-field {
        font-size: 16px;
        padding: 8px 0px;
    }

    .react-international-phone-input {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 16px !important;
    }

}