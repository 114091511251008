.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  padding-left: .75rem;
  padding-left: var(--bs-gutter-x, .75rem);
  padding-right: .75rem;
  padding-right: var(--bs-gutter-x, .75rem);
  width: 100%;
}

.bd-header {
  font-size: 40px;
  font-weight: 550;
  color: #123658
}

.main-row {
  display: flex;
}

.slick-slider {
  width: 100%
}

/* Accordian  */
.appFrame {
  width: 100%;
  height: 580px;
  border-radius: 25px;
}

article.accordion {
  display: block;
  overflow: auto;
}

article.accordion section {
  position: relative;
  display: block;
  float: left;
  width: 5em;
  height: 12em;
  margin: 0 0 0 0;
  color: #666;
  overflow: hidden;
  height: 100%;
}

#acc0 {
  background-color: #1764a8 !important;
}

#acc1 {
  background-color: #0f4371 !important;
}

#acc2 {
  background-color: #0e3a60 !important;
}

#acc3 {
  background-color: #043058 !important;
}

#acc4 {
  background-color: #122844 !important;
}

.acc-content {
  display: none;
  width: 400px;
}

.active-hori-accordian {
  background: #1764a8;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding: 40px;
  width: 73% !important;
  height: 100% !important;
}

.active-hori-accordian>div {
  display: block;
}
.active-hori-accordian>h2{
  display: none;
}

:target #text-hidd {
  visibility: visible;
}

#text-hidd {
  visibility: hidden;
}

#bd-num {
  visibility: visible;
  line-height: 50px;
}
#bd-num::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 10px;
  background-color: #F2BE1A;
  border-radius: 5px;
  display: block;
  left: -36%;
  top: 0;
}
:target #bd-num {
  visibility: hidden;
}

a:hover {
  color: white
}

.size-under-line {
  text-decoration-color: #F2BE1A;
  text-underline-position: under;
}

.num-bd0 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 280px;
  left: 8px;
  text-decoration: none;
}

.num-bd1 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 220px;
  left: 11px;
  text-decoration: none;
}

.num-bd2 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 160px;
  left: 11px;
  text-decoration: none;
}

.num-bd3 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 100px;
  left: 11px;
  text-decoration: none;
}

.num-bd4 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  padding-left: 15px;
  position: absolute;
  top: 40px;
  left: 11px;
  text-decoration: none;
}

article.accordion section h2 {
  position: absolute;
  font-size: 1.5em;
  font-weight: 500;
  width: 18em;
  height: 2em;
  bottom: -1em;
  left: 11px;
  padding: 0;
  margin: 0;
  color: #ddd;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

article.accordion section h2 a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 2em;
  text-decoration: none;
  color: inherit;
  outline: 0 none;
}

article.accordion section:target h2 {
  display: none;
}

article.accordion section,
article.accordion section h2 {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}